import * as z from "zod"
import _ from "lodash"
import { PROCESSES } from "@ensol/shared/entities/projects/processes"

import { TechnicalVisitStepInput } from "./technicalVisit"
import { HardwareStepInput } from "./hardware"
import { PaymentStepInput } from "./payment"
import { SalesVisitStepInput } from "./salesVisit"
import { InstallationStepInput } from "./installation"
import { PreliminaryRequestStepInput } from "./preliminaryRequest"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { ConsuelStepInput } from "@ensol/types/forms/projects/consuel"
import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"
import { EnedisStepInput } from "@ensol/types/forms/projects/enedis"
import { ProjectEventType } from "@ensol/shared/entities/projects/events"

export const listProjectsFilters = z.object({
  search: z.string().optional(),
  installerId: z.string().nullable().optional(),
  supplierId: z.string().nullable().optional(),
  milestoneId: z.nativeEnum(_.mapValues(PROCESSES, (_, key) => key)).optional(),
  salesOwnerId: z.string().optional(),
  operationsOwnerId: z.string().optional(),
  adminOwnerId: z.string().optional(),
  statuses: z
    .array(
      z.object({
        status: z.nativeEnum(ProjectStatus),
        isIncluded: z.boolean(),
      }),
    )
    .optional(),
})

export type ListProjectsFilters = z.infer<typeof listProjectsFilters>

export const overviewProjectsFilters = listProjectsFilters.extend({
  onlyActionNeeded: z.boolean(),
})

export type OverviewProjectsFilters = z.infer<typeof overviewProjectsFilters>

export const getProjectsAlertsFilters = z.object({
  search: z.string().optional(),
  installerId: z.string().nullable().optional(),
})

export type GetProjectsAlertsFilters = z.infer<typeof getProjectsAlertsFilters>

export const projectsEventsFilters = z.object({
  types: z.array(z.nativeEnum(ProjectEventType)).optional().default([]),
  installers: z.array(z.string()).optional().default([]),
  showEventsWithoutInstaller: z.string().transform((value) => value === "true"),
  period: z.object({ from: z.coerce.date(), to: z.coerce.date() }),
})

export type ProjectsEventsFilters = z.infer<typeof projectsEventsFilters>

export const updateProjectForm = z.object({
  salesOwnerId: z.string().nullable().optional(),
  operationsOwnerId: z.string().nullable().optional(),
  adminOwnerId: z.string().nullable().optional(),
})

export type UpdateProjectInput = z.infer<typeof updateProjectForm>

export type ProcessDataUpdateInput =
  | TechnicalVisitStepInput
  | HardwareStepInput
  | PaymentStepInput
  | InstallationStepInput
  | PreliminaryRequestStepInput
  | SalesVisitStepInput
  | UpdateProjectInput
  | EnedisStepInput
  | ConsuelStepInput
  | FollowUpStepInput
