import { Button, Stack, ThemeIcon, useMantineTheme } from "@mantine/core"
import { useClickOutside, useDisclosure, useMediaQuery } from "@mantine/hooks"
import {
  IconCalendarMonth,
  IconUsers,
  IconHomeMove,
  IconHomeCog,
  IconChevronDown,
  IconChevronUp,
  TablerIcon,
  IconBriefcase,
  IconAlertCircle,
} from "@tabler/icons-react"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

import { DESKTOP_NAVBAR_WIDTH_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"
import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

const MENU_ITEMS = [
  { to: "/projects", name: "Projets", Icon: IconBriefcase },
  {
    name: "Opérations",
    Icon: IconHomeCog,
    subItems: [
      ...PROJECTS_PROCESS_VIEWS.map(({ id, name, color, Icon }) => ({
        to: `/projects/processes/${id}`,
        name,
        color,
        Icon,
      })),
      {
        to: "/projects/alerts",
        name: "Alertes",
        color: "red",
        Icon: IconAlertCircle,
      },
    ],
  },
  { to: "/events", name: "Calendrier", Icon: IconCalendarMonth },
  { to: "/prospects", name: "Prospects", Icon: IconUsers },
  { to: "/sales", name: "Tâches AE", Icon: IconHomeMove },
]

type MenuProps = {
  closeNavbar?: () => void
}

const COMMON_MENU_BUTTON_PROPS = {
  variant: "subtle",
  color: "dark.4",
  h: 60,
}

const DESKTOP_MENU_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  w: "100%",
  p: 0,
  size: "10",
  styles: {
    inner: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    section: {
      marginTop: 8,
      marginRight: 0,
    },
  },
} as const

const MOBILE_MENU_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  w: "100%",
  justify: "flex-start",
  px: 24,
  size: "md",
} as const

export const Menu = ({ closeNavbar }: MenuProps) => {
  const isSmallScreen = useMediaQuery(devices.md)

  return MENU_ITEMS.map(({ to, name, Icon, subItems }) =>
    to !== undefined ? (
      <Button
        key={to}
        component={Link}
        onClick={closeNavbar}
        leftSection={<Icon />}
        to={to}
        {...(isSmallScreen
          ? MOBILE_MENU_BUTTON_PROPS
          : DESKTOP_MENU_BUTTON_PROPS)}
      >
        {name}
      </Button>
    ) : (
      <SubMenu
        key={name}
        name={name}
        Icon={<Icon />}
        subItems={subItems}
        onClick={closeNavbar}
      />
    ),
  )
}

type SubMenuProps = {
  name: string
  Icon: ReactNode
  subItems: {
    to: string
    name: string
    color: string
    Icon: TablerIcon
  }[]
  onClick?: () => void
}

const SubMenu = ({ name, Icon, subItems, onClick }: SubMenuProps) => {
  const theme = useMantineTheme()
  const isSmallScreen = useMediaQuery(devices.md)
  const [isSubMenuOpen, { open: openSubMenu, close: closeSubMenu }] =
    useDisclosure(false)
  const ref = useClickOutside(closeSubMenu)

  return (
    <Stack ref={ref} w="100%">
      <Button
        leftSection={Icon}
        onClick={() => (isSubMenuOpen ? closeSubMenu() : openSubMenu())}
        {...(isSmallScreen
          ? {
              ...MOBILE_MENU_BUTTON_PROPS,
              rightSection: isSubMenuOpen ? (
                <IconChevronUp />
              ) : (
                <IconChevronDown />
              ),
            }
          : DESKTOP_MENU_BUTTON_PROPS)}
      >
        {name}
      </Button>
      {isSubMenuOpen && (
        <Stack
          {...(!isSmallScreen
            ? {
                pos: "absolute",
                top: 0,
                left: DESKTOP_NAVBAR_WIDTH_IN_PX,
                h: "100%",
                w: 230,
                p: 12,
                bg: "white",
                style: {
                  borderRight: "1px solid var(--mantine-color-gray-3)",
                },
              }
            : {})}
        >
          {subItems.map(({ to, name, Icon, color }) => (
            <Button
              key={to}
              component={Link}
              to={to}
              justify="flex-start"
              h="40"
              pl={{ base: 24, md: 16 }}
              variant="subtle"
              size="md"
              color="dark.4"
              fw="normal"
              leftSection={
                <ThemeIcon color={`${color}.0`} size="md">
                  <Icon size={18} color={theme.colors[color][5]} />
                </ThemeIcon>
              }
              onClick={() => {
                onClick?.()
                closeSubMenu()
              }}
            >
              {name}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
