import { useMediaQuery } from "@mantine/hooks"

import { devices } from "@ensol/entool/styles/theme"

export const MOBILE_NAVBAR_HEIGHT_IN_PX = 60
export const DESKTOP_NAVBAR_WIDTH_IN_PX = 80
export const OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX = 240
export const CONTAINER_MIN_WIDTH = "100%"
export const CONTENT_PADDING_IN_PX = { base: 20, xl: 40 }

export const useNavbarDimensions = () => {
  const isSmallScreen = useMediaQuery(devices.md)

  return {
    navbarHeight: isSmallScreen ? MOBILE_NAVBAR_HEIGHT_IN_PX : 0,
    navbarWidth: isSmallScreen ? 0 : DESKTOP_NAVBAR_WIDTH_IN_PX,
  }
}
