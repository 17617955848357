import {
  PreliminaryRequestStep as PrismaPreliminaryRequestStep,
  TechnicalVisitStep as PrismaTechnicalVisitStep,
  InstallationStep as PrismaInstallationStep,
  PaymentStep as PrismaPaymentStep,
  HardwareStep as PrismaHardwareStep,
  GreendealStep as PrismaGreendealStep,
  EnedisStep as PrismaEnedisStep,
  ConsuelStep as PrismaConsuelStep,
  EdfOaStep as PrismaEdfOaStep,
  FollowUpStep as PrismaFollowUpStep,
} from "@ensol/types/prisma-client"

export enum PreliminaryRequestStep {
  WAITING_FOR_INFO = "WAITING_FOR_INFO",
  PRELIMINARY_VALIDATION = "PRELIMINARY_VALIDATION",
  SUBMISSION_ON_HOLD = "SUBMISSION_ON_HOLD",
  SUBMISSION_PENDING = "SUBMISSION_PENDING",
  WAITING_FOR_SOLTEO = "WAITING_FOR_SOLTEO",
  TO_SUBMIT = "TO_SUBMIT",
  SUBMITTED = "SUBMITTED",
  ACKNOWLEDGMENT = "ACKNOWLEDGMENT",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_WAITING_FOR_STAKEHOLDER = "INCOMPLETE_WAITING_FOR_STAKEHOLDER",
  INCOMPLETE_TO_SUBMIT = "INCOMPLETE_TO_SUBMIT",
  INCOMPLETE_SUBMITTED = "INCOMPLETE_SUBMITTED",
  REJECTED = "REJECTED",
  REJECTED_WAITING_FOR_STAKEHOLDER = "REJECTED_WAITING_FOR_STAKEHOLDER",
  REJECTED_TO_SUBMIT = "REJECTED_TO_SUBMIT",
  REJECTED_SUBMITTED = "REJECTED_SUBMITTED",
  ABORTED = "ABORTED",
  APPROVED = "APPROVED",
  AUTHORISATION_RECEIVED = "AUTHORISATION_RECEIVED",
}

export enum TechnicalVisitStep {
  PENDING = "PENDING",
  SCHEDULING_ON_HOLD = "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE = "CANNOT_SCHEDULE",
  REQUESTED = "REQUESTED",
  INVITATION_REJECTED = "INVITATION_REJECTED",
  SCHEDULED = "SCHEDULED",
  COMPLETED = "COMPLETED",
  BEING_PROCESSED = "BEING_PROCESSED",
  UPDATE_INSTALLATION = "UPDATE_INSTALLATION",
  REJECTED = "REJECTED",
  VALIDATED = "VALIDATED",
}

export enum GreendealStep {
  EMAIL_PENDING = "EMAIL_PENDING",
  SUBMISSION_PENDING = "SUBMISSION_PENDING",
  SUBMITTED = "SUBMITTED",
  DONE_BY_CLIENT = "DONE_BY_CLIENT",
  FILE_COMPLETED = "FILE_COMPLETED",
  VALIDATED = "VALIDATED",
}

export enum InstallationStep {
  PENDING = "PENDING",
  SCHEDULING_ON_HOLD = "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE = "CANNOT_SCHEDULE",
  REQUESTED = "REQUESTED",
  SCHEDULED = "SCHEDULED",
  PROJECT_PREPARED = "PROJECT_PREPARED",
  IN_PROGRESS = "IN_PROGRESS",
  NEW_VISIT_NEEDED = "NEW_VISIT_NEEDED",
  COMPLETED = "COMPLETED",
  UPDATE_INSTALLATION = "UPDATE_INSTALLATION",
  CERTIFICATE_SENT = "CERTIFICATE_SENT",
  CERTIFICATE_SIGNED = "CERTIFICATE_SIGNED",
}

export enum PaymentStep {
  DEPOSIT_INVOICE_WAITING = "DEPOSIT_INVOICE_WAITING",
  DEPOSIT_INVOICE_TO_SEND = "DEPOSIT_INVOICE_TO_SEND",
  WAITING_FOR_DEPOSIT_PAYMENT = "WAITING_FOR_DEPOSIT_PAYMENT",
  DEPOSIT_INVOICE_PAID = "DEPOSIT_INVOICE_PAID",
  FINAL_INVOICE_TO_SEND = "FINAL_INVOICE_TO_SEND",
  WAITING_FOR_FINAL_PAYMENT = "WAITING_FOR_FINAL_PAYMENT",
  FINAL_INVOICE_PAID = "FINAL_INVOICE_PAID",
}

export enum HardwareStep {
  PREORDER_PENDING = "PREORDER_PENDING",
  PREORDER_DONE = "PREORDER_DONE",
  ORDER_PENDING = "ORDER_PENDING",
  PREPARATION_PENDING = "PREPARATION_PENDING",
  DELIVERY_PENDING = "DELIVERY_PENDING",
  ORDER_DELIVERED = "ORDER_DELIVERED",
  ORDER_CHECKED = "ORDER_CHECKED",
  ORDER_INSTALLED = "ORDER_INSTALLED",
}

export enum EnedisStep {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  VALIDATED = "VALIDATED",
  INCOMPLETE = "INCOMPLETE",
  ACTIVATION_PENDING = "ACTIVATION_PENDING",
  ACTIVATED = "ACTIVATED",
}

export enum ConsuelStep {
  PENDING = "PENDING",
  WAITING_FOR_SOLTEO = "WAITING_FOR_SOLTEO",
  SUBMITTED = "SUBMITTED",
  VISIT_NEEDED = "VISIT_NEEDED",
  VISIT_SCHEDULED = "VISIT_SCHEDULED",
  VISIT_WAITING_VALIDATION = "VISIT_WAITING_VALIDATION",
  QUALITY_PV_VISIT_NEEDED = "QUALITY_PV_VISIT_NEEDED",
  QUALITY_PV_VISIT_SCHEDULED = "QUALITY_PV_VISIT_SCHEDULED",
  QUALITY_PV_DOC_TO_SEND = "QUALITY_PV_DOC_TO_SEND",
  QUALITY_PV_WAITING_VALIDATION = "QUALITY_PV_WAITING_VALIDATION",
  WORK_NEEDED = "WORK_NEEDED",
  WORK_SCHEDULED = "WORK_SCHEDULED",
  WORK_DONE = "WORK_DONE",
  VALIDATED = "VALIDATED",
}

export enum EdfOaStep {
  EMAIL_SENT = "EMAIL_SENT",
  CERTIFICATE_TO_SEND = "CERTIFICATE_TO_SEND",
  CERTIFICATE_SENT = "CERTIFICATE_SENT",
}

export enum FollowUpStep {
  INSTALLATION_TO_VALIDATE = "INSTALLATION_TO_VALIDATE",
  LESS_THAN_1_MONTH_PRODUCING = "LESS_THAN_1_MONTH_PRODUCING",
  ONBOARDING_PENDING = "ONBOARDING_PENDING",
  ONBOARDING_DONE = "ONBOARDING_DONE",
  MORE_THAN_3_MONTHS_PRODUCING = "MORE_THAN_3_MONTHS_PRODUCING",
  MORE_THAN_6_MONTHS_PRODUCING = "MORE_THAN_6_MONTHS_PRODUCING",
  MORE_THAN_12_MONTHS_PRODUCING = "MORE_THAN_12_MONTHS_PRODUCING",
}

export const PROCESSES = {
  preliminaryRequestStep: PreliminaryRequestStep,
  technicalVisitStep: TechnicalVisitStep,
  installationStep: InstallationStep,
  paymentStep: PaymentStep,
  hardwareStep: HardwareStep,
  greendealStep: GreendealStep,
  enedisStep: EnedisStep,
  consuelStep: ConsuelStep,
  edfOaStep: EdfOaStep,
  followUpStep: FollowUpStep,
}

export type ProcessId = keyof typeof PROCESSES

// Object.keys return type is string[], so we need to cast it
export const PROJECT_MILESTONE_FIELDS = [
  "status",
  ...Object.keys(PROCESSES),
] as Array<keyof typeof PROCESSES | "status">

export const getStepIndex = (step: ProcessStep | null, processId: ProcessId) =>
  Object.values(PROCESSES[processId]).findIndex(
    (processStep) => processStep === step,
  )

export type ProcessStep =
  | PrismaPreliminaryRequestStep
  | PrismaTechnicalVisitStep
  | PrismaInstallationStep
  | PrismaPaymentStep
  | PrismaHardwareStep
  | PrismaGreendealStep
  | PrismaEnedisStep
  | PrismaConsuelStep
  | PrismaEdfOaStep
  | PrismaFollowUpStep
