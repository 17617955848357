import { CopyButton, Button, MantineStyleProps, Text } from "@mantine/core"
import {
  IconClipboardCheck,
  IconClipboardText,
  TablerIcon,
} from "@tabler/icons-react"

type Props = {
  text: string
  Icon?: TablerIcon
} & MantineStyleProps

export const CopiableText = ({ text, Icon, ...props }: Props) => (
  <CopyButton value={text}>
    {({ copied, copy }) => (
      <Button
        onClick={copy}
        mih="fit-content"
        leftSection={
          copied ? (
            <IconClipboardCheck size={18} color="var(--mantine-color-blue-8)" />
          ) : Icon ? (
            <Icon size={18} color="var(--mantine-color-blue-8)" />
          ) : (
            <IconClipboardText size={18} color="var(--mantine-color-blue-8)" />
          )
        }
        styles={{
          root: {
            color: "black",
            borderRadius: 4,
            backgroundColor: "var(--mantine-color-gray-1)",
          },
          inner: { justifyContent: "space-between" },
          label: {
            whiteSpace: "break-spaces",
            textAlign: "left",
            lineHeight: "20px",
          },
        }}
        {...props}
      >
        <Text size="md">{text}</Text>
      </Button>
    )}
  </CopyButton>
)
