import { useMediaQuery } from "@mantine/hooks"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { devices } from "@ensol/entool/styles/theme"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjects = () => {
  const isMobile = useMediaQuery(devices.sm)
  const {
    search,
    installerId,
    supplierId,
    salesOwnerId,
    operationsOwnerId,
    adminOwnerId,
  } = useProjectsSearch()

  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      installerId: installerId ?? undefined,
      supplierId: supplierId ?? undefined,
      salesOwnerId: salesOwnerId ?? undefined,
      operationsOwnerId: operationsOwnerId ?? undefined,
      adminOwnerId: adminOwnerId ?? undefined,
      statuses: [{ status: ProjectStatus.CREATED, isIncluded: false }],
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })

  return {
    projectsQuery,
    isSearching: search !== "",
  }
}
