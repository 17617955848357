import { TitleOrder } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconBatteryCharging } from "@tabler/icons-react"
import { useEffect } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { batteryInstallationSchema } from "@ensol/types/forms/installations/battery"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

import { DEFAULT_BATTERY_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import { InverterType } from "@ensol/shared/material/photovoltaic/inverters"

import { ProductWrapper } from "@ensol/entool/components/form/Installation/ProductWrapper"
import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { useBatteryMaterialOptions } from "@ensol/entool/utils/installations/battery"

import { ExtraWorksSelect } from "./ExtraWorksSelect"

type Props = {
  titleOrder?: TitleOrder
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  onChange: (data: InstallationsResponses.BatteryInstallation | null) => void
  setError: (error: string) => void
  clearError: () => void
  inverterType?: InverterType
}

export const BatteryInstallation = ({
  titleOrder,
  batteryInstallation,
  onChange,
  ...props
}: Props) => (
  <ProductWrapper
    type="Battery"
    installationHasProduct={batteryInstallation !== null}
    addProduct={() => onChange(DEFAULT_BATTERY_INSTALLATION)}
    deleteProduct={() => onChange(null)}
    title="Batterie"
    titleOrder={titleOrder}
    Icon={IconBatteryCharging}
    addButtonLabel="Ajouter une batterie"
  >
    {batteryInstallation !== null && (
      <BatteryInstallationForm
        batteryInstallation={batteryInstallation}
        onChange={onChange}
        {...props}
      />
    )}
  </ProductWrapper>
)

const BatteryInstallationForm = ({
  batteryInstallation,
  onChange,
  setError,
  clearError,
  inverterType,
}: Omit<Props, "titleOrder"> & {
  batteryInstallation: InstallationsResponses.BatteryInstallation
}) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(batteryInstallationSchema.merge(extraWorksSchema)),
    initialValues: batteryInstallation,
  })

  const { batteryOptions } = useBatteryMaterialOptions({
    inverterType,
    batteryType: form.values.type,
  })

  useEffect(() => {
    if (findSelectedOption(form.values.type, batteryOptions)?.incompatible) {
      form.setFieldError("type", "Batterie non compatible")
    } else {
      form.clearFieldError("type")
    }
  }, [form, batteryOptions])

  useEffect(() => {
    if (!isFormValid(form)) {
      setError("battery installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <LegacyField name="Type">
        <RadioGroup options={batteryOptions} {...form.getInputProps("type")} />
      </LegacyField>
      <LegacyField name="Travaux supplémentaires" noBorder>
        <ExtraWorksSelect {...form.getInputProps("extraWorks")} />
      </LegacyField>
    </>
  )
}
