import { Badge, Box, Card, Group, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconLayoutSidebarRightCollapseFilled,
  IconTimelineEvent,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { IconButton } from "@ensol/entool/components/IconButton"
import { ClientInfo } from "@ensol/entool/pages/OpsProjectsOverview/Row/ClientInfo"
import { DueDateReminder } from "@ensol/entool/pages/OpsProjectsOverview/Row/DueDateReminder"
import { ProjectKeyFigures } from "@ensol/entool/pages/OpsProjectsOverview/Row/ProjectKeyFigures"
import {
  PROJECT_TASK_CONFIG,
  STATUS_SECTION_CONFIG,
} from "@ensol/entool/pages/OpsProjectsOverview/config"
import { ProjectDrawerTab } from "@ensol/entool/pages/OpsProjectsOverview/useProjectDrawer"
import { devices } from "@ensol/entool/styles/theme"

import { ProjectIndicators } from "./ProjectIndicators"
import { ProjectTaskIcon } from "./ProjectTaskIcon"

type Props = {
  project: ProjectResponses.ProjectOverview
  isDrawerOpen: boolean
  openDrawer: (
    project: ProjectResponses.ProjectOverview,
    tab: ProjectDrawerTab,
  ) => void
}

export const ProjectRow = ({ project, isDrawerOpen, openDrawer }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  const { isActionNeeded, taskDetails } = project
  const { getReferenceDates } = STATUS_SECTION_CONFIG[project.status]

  return (
    <Card
      withBorder
      bg={isDrawerOpen ? "blue.1" : "white"}
      p="0"
      style={{
        borderColor: isDrawerOpen
          ? "var(--mantine-color-blue-7)"
          : "var(--mantine-color-gray-4)",
      }}
    >
      <Box
        p="12"
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? 16 : 32,
        }}
      >
        <Group align="flex-start" justify="space-between" flex="1">
          <Stack gap="4" flex={1} align="flex-start">
            <Group gap="4" align="center" flex={1}>
              <ProjectIndicators project={project} />
              <ClientInfo project={project} />
              {taskDetails?.dueDate && (
                <DueDateReminder
                  project={project}
                  dueDateConfig={taskDetails.dueDate}
                  isActionNeeded={isActionNeeded}
                />
              )}
            </Group>
            {taskDetails !== null && (
              <Badge
                variant="light"
                color={PROJECT_TASK_CONFIG[taskDetails.task].color}
                size="sm"
                styles={{
                  label: { display: "flex", alignItems: "center", gap: 4 },
                }}
              >
                <ProjectTaskIcon
                  type={taskDetails.type}
                  color={PROJECT_TASK_CONFIG[taskDetails.task].color}
                />
                {PROJECT_TASK_CONFIG[taskDetails.task].label}
              </Badge>
            )}
          </Stack>
          <ProjectKeyFigures
            project={project}
            getReferenceDates={getReferenceDates}
          />
        </Group>
        <Group gap="8">
          <IconButton
            Icon={<IconTimelineEvent size={18} />}
            onClick={() => openDrawer(project, ProjectDrawerTab.DETAILS)}
            ariaLabel="Ouvrir les details du projet"
          />
          <IconButton
            Icon={<IconLayoutSidebarRightCollapseFilled size={22} />}
            onClick={() => openDrawer(project, ProjectDrawerTab.NOTES)}
            ariaLabel="Ouvrir les notes du projet"
          />
        </Group>
      </Box>
    </Card>
  )
}
