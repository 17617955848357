import * as z from "zod"

export const salesVisitFormSchema = z.object({
  salesOwnerId: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitPanelsLayoutPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitK2ReportPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitFinancingMethod: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitSuggestedLocationPhotos: z.array(z.string()),
  technicalVisitClientAvailabilities: z.string().optional(),
  technicalVisitSalesComments: z.string().optional(),
  electricityBillPath: z.string().nullable().optional(),
})

export type SalesVisitStepInput = z.infer<typeof salesVisitFormSchema>
