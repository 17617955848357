import { ActionIcon, Table, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getFullName } from "@ensol/shared/entities/prospects"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import { useDeleteProspectMutation } from "@ensol/entool/queries/prospects"

type Props = {
  prospect: ProspectResponses.Prospect
  installationsCount: number
}

export const ProspectRow = ({ prospect, installationsCount }: Props) => {
  const navigate = useNavigate()
  const { mutateAsync: deleteProspect, isPending: isProspectDeleting } =
    useDeleteProspectMutation(prospect.id)

  return (
    <Table.Tr
      key={prospect.id}
      onClick={() => navigate(`/prospects/${prospect.id}`)}
      style={{ cursor: "pointer" }}
    >
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {dayjs(prospect.createdAt).format("DD MMMM")}
      </Table.Td>
      <Table.Td>{getFullName(prospect)}</Table.Td>
      <Table.Td>{prospect.email}</Table.Td>
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {formatPhoneNum(prospect.phone)}
      </Table.Td>
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {installationsCount}
      </Table.Td>
      <Table.Td>
        <HubspotLink
          hubspotContactId={prospect.hubspotContactId}
          w="fit-content"
          p="0"
          variant="transparent"
          emptyContent="-"
        />
      </Table.Td>
      <Table.Td>
        <ActionIcon
          loading={isProspectDeleting}
          variant="subtle"
          aria-label="Supprimer"
          color="gray"
          p="4"
          onClick={(e) => {
            e.stopPropagation()
            modals.openConfirmModal({
              title: "Supprimer le prospect",
              children: (
                <Text size="sm">
                  Êtes-vous sûr(e) de vouloir supprimer ce prospect ? Cette
                  action est irréversible.
                </Text>
              ),
              confirmProps: { color: "red" },
              centered: true,
              labels: {
                confirm: "Supprimer le prospect",
                cancel: "Annuler",
              },
              onConfirm: () => {
                deleteProspect()
              },
            })
          }}
        >
          <IconTrash color="red" />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  )
}
