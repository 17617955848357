import { Stack, Loader, Text, Image } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import { IconCameraPlus, IconFileAlert, IconUpload } from "@tabler/icons-react"
import { useState } from "react"

import { getExtension } from "@ensol/shared/utils/files"

import { FILE_THUMBNAIL_HEIGHT_IN_PX } from "@ensol/entool/components/entities/File/constants"
import { useUploadFileMutation } from "@ensol/entool/queries/files"
import {
  compressImage,
  convertHeicToJpeg,
  isImage,
} from "@ensol/entool/utils/files/images"

import { FileUploaderProps } from "./types"

export const FileUploader = ({
  accept,
  capture,
  width,
  onSuccess,
  uploadUrl = "/files",
  error,
  placeholderImage,
  fileUploadedCount,
}: FileUploaderProps) => {
  const [isFileProcessing, setIsFileProcessing] = useState(false)
  const { mutateAsync: uploadFile, isPending } = useUploadFileMutation(
    uploadUrl,
    onSuccess,
  )

  return (
    <Dropzone
      onDrop={async ([file]: File[]) => {
        if (isImage(getExtension(file.name))) {
          let image = file
          setIsFileProcessing(true)
          if (file.type === "image/heic") {
            image = await convertHeicToJpeg(file)
          }
          compressImage(image, async (compressedFile) => {
            await uploadFile(compressedFile)
            setIsFileProcessing(false)
          })
        } else {
          await uploadFile(file)
        }
      }}
      accept={accept}
      inputProps={{ capture }}
      w={width}
      style={{ cursor: "pointer" }}
    >
      <Stack gap="4">
        <DropzoneContent
          Component={Dropzone.Idle}
          color={error ? "red" : "var(--mantine-color-gray-4)"}
        >
          {isPending || isFileProcessing ? (
            <Loader color="var(--mantine-color-gray-4)" size={32} />
          ) : capture === "environment" ? (
            fileUploadedCount === 0 && placeholderImage ? (
              <>
                <IconCameraPlus
                  color={error ? "var(--mantine-color-red-text)" : "gray"}
                  size={32}
                  style={{
                    position: "absolute",
                    bottom: 5,
                    right: 5,
                    zIndex: 1,
                  }}
                />
                <Image
                  src={placeholderImage}
                  w="100%"
                  h="100%"
                  fit="contain"
                  opacity={0.3}
                />
              </>
            ) : (
              <IconCameraPlus
                color={error ? "var(--mantine-color-red-text)" : "gray"}
                size={32}
              />
            )
          ) : (
            <IconUpload
              color={error ? "var(--mantine-color-red-text)" : "gray"}
              size={32}
            />
          )}
        </DropzoneContent>
        <DropzoneContent Component={Dropzone.Accept} color="green">
          <IconUpload color="green" size={32} />
        </DropzoneContent>
        <DropzoneContent Component={Dropzone.Reject} color="red">
          <IconFileAlert color="var(--mantine-color-red-text)" size={32} />
          <Text c="red" size="xs">
            Type de fichier invalide
          </Text>
        </DropzoneContent>
        {error && (
          <Text c="red" size="xs">
            {error}
          </Text>
        )}
      </Stack>
    </Dropzone>
  )
}

type DropzoneContentProps = {
  Component: React.ComponentType<{ children: React.ReactNode }>
  color: string
  children: React.ReactNode
}

const DropzoneContent = ({
  Component,
  color,
  children,
}: DropzoneContentProps) => (
  <Component>
    <Stack
      h={FILE_THUMBNAIL_HEIGHT_IN_PX}
      align="center"
      justify="center"
      pos="relative"
      style={{
        borderRadius: 8,
        border: `1px solid ${color}`,
      }}
    >
      {children}
    </Stack>
  </Component>
)
