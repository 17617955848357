import { Stack, TitleOrder, Divider } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconSolarPanel } from "@tabler/icons-react"
import { useEffect } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { extraPanelsInstallationSchema } from "@ensol/types/forms/installations/photovoltaic"
import { HouseSignedInstallations } from "@ensol/types/installation"
import { ProductionDataSource } from "@ensol/types/simulation"

import {
  computePanelsCount,
  computePanelsCapacity,
  computeSignedInstallationsCapacity,
  computeSignedInstallationsPanelsCount,
} from "@ensol/shared/entities/installations/energy"

import { ExtraWorksSelect } from "@ensol/entool/components/form/Installation/ExtraWorksSelect"
import { ProductWrapper } from "@ensol/entool/components/form/Installation/ProductWrapper"
import { RoofSectionsPanelsForm } from "@ensol/entool/components/form/Installation/RoofSectionsPanelsForm"
import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  titleOrder?: TitleOrder
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation | null
  onChange: (
    data: InstallationsResponses.ExtraPanelsInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    | "currentType"
    | "hasFlatRoof"
    | "hasGroundInstallation"
    | "roofSections"
    | "postcode"
    | "constructionYear"
    | "roofType"
  >
  signedInstallations: HouseSignedInstallations
  productionDataSource?: ProductionDataSource
  estimatedProduction?: number
  yearlyConsumption?: number
  maxPanelsCount?: number
}

export const ExtraPanelsInstallation = ({
  titleOrder,
  extraPanelsInstallation,
  signedInstallations,
  onChange,
  ...props
}: Props) => (
  <ProductWrapper
    type="Panels"
    installationHasProduct={extraPanelsInstallation !== null}
    addProduct={() =>
      onChange({
        panelType: signedInstallations.photovoltaicInstallation.panelType,
        inverterType: signedInstallations.photovoltaicInstallation.inverterType,
        panelsCount: 0,
        roofSectionsWithPanels: [],
        extraWorks: [],
      })
    }
    deleteProduct={() => onChange(null)}
    title="Panneaux photovoltaïques supplémentaires"
    titleOrder={titleOrder}
    Icon={IconSolarPanel}
    addButtonLabel="Ajouter des panneaux photovoltaïques supplémentaires"
  >
    {extraPanelsInstallation !== null && (
      <PanelsInstallationForm
        extraPanelsInstallation={extraPanelsInstallation}
        signedInstallations={signedInstallations}
        onChange={onChange}
        {...props}
      />
    )}
  </ProductWrapper>
)

const PanelsInstallationForm = ({
  extraPanelsInstallation,
  onChange,
  setError,
  clearError,
  house,
  signedInstallations,
  productionDataSource,
  estimatedProduction,
  yearlyConsumption,
  maxPanelsCount,
}: Omit<Props, "titleOrder"> & {
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation
}) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(
      extraPanelsInstallationSchema.merge(extraWorksSchema),
    ),
    initialValues: {
      ...extraPanelsInstallation,
      roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
        house.roofSections,
        extraPanelsInstallation.roofSectionsWithPanels,
      ),
    },
  })

  const { panelType, panelsCount, roofSectionsWithPanels, inverterType } =
    form.values
  const totalPanelsCount =
    panelsCount + computeSignedInstallationsPanelsCount(signedInstallations)

  const installedCapacity =
    computePanelsCapacity({ panelType, panelsCount }) +
    computeSignedInstallationsCapacity(signedInstallations)

  const { panelsOptions, invertersOptions } = usePhotovoltaicMaterialOptions({
    panelType,
    installedCapacity,
    panelsCount: totalPanelsCount,
    inverterType,
    currentType: house.currentType,
  })

  const masterInverterOption = invertersOptions.find(
    (option) => option.value === inverterType,
  )!

  useEffect(() => {
    if (findSelectedOption(panelType, panelsOptions)?.incompatible) {
      form.setFieldError("panelType", "Type de panneau invalide")
    } else {
      form.clearFieldError("panelType")
    }
  }, [form, panelType, panelsOptions])

  useEffect(() => {
    form.setFieldValue(
      "panelsCount",
      computePanelsCount(roofSectionsWithPanels),
    )
  }, [form, roofSectionsWithPanels])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("panels installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <LegacyField name="Type Panneaux">
        <RadioGroup
          options={panelsOptions}
          {...form.getInputProps("panelType")}
        />
      </LegacyField>
      <Stack>
        <RoofSectionsPanelsForm
          roofSectionsWithPanels={form.values.roofSectionsWithPanels}
          house={house}
          panelType={panelType}
          signedInstallations={signedInstallations}
          productionDataSource={productionDataSource}
          yearlyConsumption={yearlyConsumption}
          estimatedProduction={estimatedProduction}
          maxPanelsCount={maxPanelsCount}
          totalPanelsCount={totalPanelsCount}
          {...getSubformActions(form, "roofSectionsWithPanels")}
        />
        <Divider mt={12} mb={24} />
        <LegacyField name="Onduleur installé">
          <RadioGroup
            options={[masterInverterOption]}
            {...form.getInputProps("inverterType")}
          />
        </LegacyField>
        <LegacyField name="Travaux supplémentaires">
          <ExtraWorksSelect {...form.getInputProps("extraWorks")} />
        </LegacyField>
      </Stack>
    </>
  )
}
