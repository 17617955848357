import { Avatar, Card, Group, Stack, Text } from "@mantine/core"
import dayjs, { extend } from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import entoolLogo from "@ensol/entool/assets/entoolLogo.svg"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"

extend(relativeTime)

type Props = {
  note: ProjectResponses.ProjectNote
}

export const Note = ({ note }: Props) => {
  const currentUser = useAuthenticatedUser()
  const isCurrentUserNote = note.userId === currentUser?.id
  return (
    <Stack
      pt={12}
      key={note.id}
      gap={2}
      pl={isCurrentUserNote ? 24 : 0}
      pr={isCurrentUserNote ? 0 : 24}
      align={isCurrentUserNote ? "flex-end" : "flex-start"}
    >
      <Group gap="4">
        <Avatar src={note.user ? note.user.avatarUrl : entoolLogo} size="16" />
        <Text>{note.user ? getFullName(note.user) : <i>Entool</i>}</Text>
        <Text size="xs" c="gray.7">
          {dayjs(note.createdAt).fromNow(true)}
        </Text>
      </Group>
      <Card
        withBorder
        padding={8}
        ml={isCurrentUserNote ? 0 : 16}
        bg={isCurrentUserNote ? "gray.0" : "white"}
      >
        <Text style={{ wordBreak: "break-word", whiteSpace: "pre-line" }}>
          {note.content}
        </Text>
      </Card>
    </Stack>
  )
}
