export type PanelInfo = {
  name: string
  brand: string
  power: number
  price: number
  efficiency: number
  warranty: number
  lengthInMillimeters: number
  widthInMillimeters: number
  techDocUrl: string
  disabled: boolean
}

// TODO FIXME: Cannot import enum types in frontend https://github.com/prisma/prisma/issues/19261
export const PanelType = {
  DUALSUN_425_TOPCON: "DUALSUN_425_TOPCON",
  DUALSUN_500_TOPCON: "DUALSUN_500_TOPCON",
  VOLTEC_355_TARKA: "VOLTEC_355_TARKA",
  DUALSUN_500_BLACK: "DUALSUN_500_BLACK", // Deprecated
  DUALSUN_425_BLACK: "DUALSUN_425_BLACK", // Deprecated
} as const

export type PanelType = keyof typeof PanelType

export const PANELS: Record<PanelType, PanelInfo> = {
  DUALSUN_425_TOPCON: {
    name: "DualSun FLASH 425 TopCon",
    brand: "DualSun",
    power: 0.425,
    price: 150,
    efficiency: 0.21,
    warranty: 30,
    lengthInMillimeters: 1722,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/2023/05/dualsun-fr-fiche-technique-flash-425-half-cut-glass-glass-topcon.pdf",
    disabled: false,
  },
  DUALSUN_500_TOPCON: {
    name: "DualSun FLASH 500 TopCon",
    brand: "DualSun",
    power: 0.5,
    price: 175,
    efficiency: 0.2261,
    warranty: 30,
    lengthInMillimeters: 1950,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/espace-professionnel/fiche-technique/flash-500-half-cut-glass-glass-topcon/",
    disabled: false,
  },
  VOLTEC_355_TARKA: {
    name: " Voltec TARKA 355",
    brand: "Voltec",
    power: 0.355,
    price: 192,
    efficiency: 0.1857,
    warranty: 25,
    lengthInMillimeters: 1835,
    widthInMillimeters: 1042,
    techDocUrl:
      "https://dualsun.com/espace-professionnel/fiche-technique/flash-500-half-cut-glass-glass-topcon/",
    disabled: false,
  },
  DUALSUN_500_BLACK: {
    name: "DualSun FLASH 500 Half-Cut Black",
    brand: "DualSun",
    power: 0.5,
    price: 175,
    efficiency: 0.2,
    warranty: 25,
    lengthInMillimeters: 2094,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/dualsun-fr-fiche-technique-flash-500-half-cut-black.pdf",
    disabled: true,
  },
  DUALSUN_425_BLACK: {
    name: "DualSun FLASH 425 Shingle Black",
    brand: "DualSun",
    power: 0.425,
    price: 170,
    efficiency: 0.2,
    warranty: 25,
    lengthInMillimeters: 1899,
    widthInMillimeters: 1096,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/dualsun-fr-fiche-technique-flash-425-shingle-black.pdf",
    disabled: true,
  },
}

export const getPanel = (panelType: PanelType): PanelInfo => PANELS[panelType]
