import * as z from "zod"

import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"
import { InstallationUpdateType } from "@ensol/types/installation"
import {
  extraPanelsInstallationSchema,
  photovoltaicInstallationSchema,
} from "@ensol/types/forms/installations/photovoltaic"
import { batteryInstallationSchema } from "@ensol/types/forms/installations/battery"
import { evChargerInstallationSchema } from "@ensol/types/forms/installations/evCharger"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

export const createInstallationSchema = z.object({
  name: z.string(),
  productionDataSource: z.nativeEnum(ProductionDataSource),
  consumptionDataSource: z.nativeEnum(ConsumptionDataSource),
  monthlyBill: z.number().positive(),
  yearlyConsumption: z.number().positive(),
  autoConsumptionPercent: z.number().nonnegative(),
  photovoltaicInstallation: photovoltaicInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  extraPanelsInstallation: extraPanelsInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  batteryInstallation: batteryInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  evChargerInstallation: evChargerInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  discount: z.number().nonnegative().optional().nullable(),
  referrerId: z.string().optional().nullable(),
  houseId: z.string(),
  prospectId: z.string(),
  bundled: z.boolean().optional(),
  horizonProfileFilePath: z.string().nullable(),
})

export type CreateInstallationInput = z.infer<typeof createInstallationSchema>

export const salesUpdateInstallationSchema = createInstallationSchema.extend({
  updateType: z.literal(InstallationUpdateType.SALES),
})

export type SalesUpdateInstallationInput = z.infer<
  typeof salesUpdateInstallationSchema
>

export const updateInstallationStepSchema = z.object({
  updateType: z.literal(InstallationUpdateType.TECHNICAL_VISIT),
  photovoltaicInstallation: photovoltaicInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  extraPanelsInstallation: extraPanelsInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  batteryInstallation: batteryInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  evChargerInstallation: evChargerInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
})

export type UpdateInstallationStepInput = z.infer<
  typeof updateInstallationStepSchema
>

export const updateInstallationSchema = z.discriminatedUnion("updateType", [
  salesUpdateInstallationSchema,
  updateInstallationStepSchema,
])

export type UpdateInstallationInput =
  | SalesUpdateInstallationInput
  | UpdateInstallationStepInput
