import { Stack, Button, Textarea, SimpleGrid } from "@mantine/core"
import { IconCurrencyEuro } from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  DepositToSendStepInput,
  depositToSendStepFormSchema,
} from "@ensol/types/forms/projects/payment"

import { computeQuoteDetails } from "@ensol/shared/entities/quotes/details"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT, formatCurrency } from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useCreateEstimateMutation } from "@ensol/entool/queries/projects"
import { getPennylaneEstimateLink } from "@ensol/entool/utils/pennylane"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

import { PaymentInfo } from "./PaymentInfo"
import { PennylaneLink } from "./PennylaneLink"

type Props = {
  project: ProjectResponses.Project
  installation: InstallationsResponses.Installation
}

export const GenerateEstimateForm = ({ project, installation }: Props) => {
  const { mutateAsync: createEstimate, isPending: isEstimatePending } =
    useCreateEstimateMutation(project.id)

  const form = useProcessForm<DepositToSendStepInput>({
    schema: depositToSendStepFormSchema,
    initialValues: {
      paymentNotes: project.paymentNotes,
      paymentHoldEndDate: project.paymentHoldEndDate,
    },
    projectId: project.id,
    processId: "paymentStep",
  })
  const { activeQuote, prospect } = installation

  const details = activeQuote?.details ?? computeQuoteDetails(installation)

  return (
    <SimpleGrid cols={2} spacing={32}>
      <Stack>
        <Field name="Devis" noBorder>
          {project.pennylaneEstimateId === null ? (
            <Button
              loading={isEstimatePending}
              onClick={() => createEstimate()}
              leftSection={<IconCurrencyEuro />}
              color="rgb(0, 102, 102)"
            >
              Générer le devis sur Pennylane
            </Button>
          ) : (
            <PennylaneLink
              label="Voir sur Pennylane"
              href={getPennylaneEstimateLink(
                prospect.firstName,
                prospect.lastName,
              )}
            />
          )}
        </Field>
        <Field name="Notes" noBorder>
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("paymentNotes")}
          />
        </Field>
        <Field
          name="Date de relance"
          subtitle="À remplir si la facture ne peut pas encore être envoyée."
          noBorder
        >
          <DateInput
            w={FIELD_WIDTH}
            clearable
            valueFormat={DISPLAY_DATE_FORMAT}
            minDate={new Date()}
            {...form.getInputProps("paymentHoldEndDate")}
          />
        </Field>
      </Stack>
      <Stack>
        <Field
          name="Montant total TTC devis"
          subtitle="Vérifiez que ce montant correspond à celui dans PennyLane."
          noBorder
        >
          <TextInfo value={formatCurrency(details.totals.finalCost)} />
        </Field>

        <Field name="Devis" noBorder>
          {activeQuote !== null && activeQuote.quotePath !== null ? (
            <MultiFilePreview
              filesPaths={[activeQuote.quotePath]}
              fileName={getProspectFileName("devis", prospect)}
            />
          ) : (
            <NotFound type="file" />
          )}
        </Field>
      </Stack>
      <PaymentInfo project={project} />
    </SimpleGrid>
  )
}
