import { Card, Group, ThemeIcon, Text, Stack } from "@mantine/core"
import {
  IconBatteryCharging,
  IconChargingPile,
  IconSolarPanel,
  IconSolarPanel2,
} from "@tabler/icons-react"

const PRODUCT_TYPES = {
  photovoltaic: {
    name: "Photovoltaïque",
    icon: IconSolarPanel,
  },
  panels: {
    name: "Panneaux",
    icon: IconSolarPanel2,
  },
  battery: {
    name: "Batterie",
    icon: IconBatteryCharging,
  },
  evCharger: {
    name: "Borne de recharge",
    icon: IconChargingPile,
  },
}

type Props = {
  installationType: keyof typeof PRODUCT_TYPES
  productNames: string[]
}

export const SignedInstallationCard = ({
  installationType,
  productNames,
}: Props) => {
  if (productNames.length === 0) return null

  const installationInfo = PRODUCT_TYPES[installationType]
  return (
    <Card withBorder radius="md" px={12} py={8}>
      <Stack gap={4}>
        <Group mb={2} gap={4}>
          <ThemeIcon variant="subtle">
            <installationInfo.icon />
          </ThemeIcon>
          <Text fw={500}>{installationInfo.name}</Text>
        </Group>
        {productNames.map((name) => (
          <Text key={name} fz="sm">
            {name}
          </Text>
        ))}
      </Stack>
    </Card>
  )
}
