import { TitleOrder, Text, Group } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconChargingPile, IconInfoCircle } from "@tabler/icons-react"
import { useEffect } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { evChargerInstallationSchema } from "@ensol/types/forms/installations/evCharger"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

import { DEFAULT_EV_CHARGER_INSTALLATION } from "@ensol/shared/entities/installations/defaults"

import { ExtraWorksSelect } from "@ensol/entool/components/form/Installation/ExtraWorksSelect"
import { ProductWrapper } from "@ensol/entool/components/form/Installation/ProductWrapper"
import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import {
  EV_CHARGER_OPTIONS,
  EV_CHARGER_SUBSIDIES_OPTIONS,
} from "@ensol/entool/utils/installations/evCharger"

type Props = {
  titleOrder?: TitleOrder
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  onChange: (data: InstallationsResponses.EvChargerInstallation | null) => void
  setError: (error: string) => void
  clearError: () => void
}

export const EvChargerInstallation = ({
  titleOrder,
  evChargerInstallation,
  onChange,
  ...props
}: Props) => (
  <ProductWrapper
    type="EvCharger"
    installationHasProduct={evChargerInstallation !== null}
    addProduct={() => onChange(DEFAULT_EV_CHARGER_INSTALLATION)}
    deleteProduct={() => onChange(null)}
    title="Borne de recharge"
    titleOrder={titleOrder}
    Icon={IconChargingPile}
    addButtonLabel=" Ajouter une borne de recharge"
  >
    {evChargerInstallation !== null && (
      <EvChargerInstallationForm
        evChargerInstallation={evChargerInstallation}
        onChange={onChange}
        {...props}
      />
    )}
  </ProductWrapper>
)

const EvChargerInstallationForm = ({
  evChargerInstallation,
  onChange,
  setError,
  clearError,
}: Omit<Props, "titleOrder"> & {
  evChargerInstallation: InstallationsResponses.EvChargerInstallation
}) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(evChargerInstallationSchema.merge(extraWorksSchema)),
    initialValues: evChargerInstallation,
  })

  useEffect(() => {
    if (!isFormValid(form)) {
      setError("ev charger installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <LegacyField name="Type">
        <RadioGroup
          options={EV_CHARGER_OPTIONS}
          {...form.getInputProps("type")}
        />
        <Group gap="4">
          <IconInfoCircle size="16" color="gray" />
          <Text size="sm" c="gray.7">
            Un véhicule électrique consomme ~1800 kWh/an
          </Text>
        </Group>
      </LegacyField>
      <LegacyField name="Supplément pose externe TTC">
        <NumberInput
          suffix="€"
          min={0}
          {...form.getInputProps("additionalCost")}
        />
      </LegacyField>
      <LegacyField name="Travaux supplémentaires">
        <ExtraWorksSelect {...form.getInputProps("extraWorks")} />
      </LegacyField>
      <LegacyField name="Subventions" noBorder>
        <RadioGroup
          options={EV_CHARGER_SUBSIDIES_OPTIONS}
          nullable
          {...form.getInputProps("subsidyType")}
        />
      </LegacyField>
    </>
  )
}
