import dayjs from "dayjs"

import { PreliminaryRequestStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const PreliminaryRequestInfo = ({
  project: {
    preliminaryRequestStep,
    preliminaryRequestExpectedValidationDate,
    preliminaryRequestDeadlineDate,
    preliminaryRequestLastContactDate,
    preliminaryRequestReference,
    preliminaryRequestNotes,
    preliminaryRequestRequestedChangesReasons,
    preliminaryRequestAwaitedStakeholder,
  },
  milestoneId,
}: InfoComponentProps) => {
  const isProcessCompleted =
    preliminaryRequestStep === PreliminaryRequestStep.AUTHORISATION_RECEIVED ||
    preliminaryRequestStep === PreliminaryRequestStep.ABORTED

  return (
    <ProcessInfos
      infos={[
        {
          name: "Dernier contact",
          value: formatDate(
            preliminaryRequestLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
        },
        {
          name: "Date prévue",
          value: formatDate(
            preliminaryRequestExpectedValidationDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          color:
            !isProcessCompleted &&
            dayjs().isAfter(preliminaryRequestExpectedValidationDate)
              ? "yellow.8"
              : undefined,
        },
        {
          name: "Date d'échéance",
          value: formatDate(
            preliminaryRequestDeadlineDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          color:
            !isProcessCompleted &&
            dayjs().isAfter(preliminaryRequestDeadlineDate)
              ? "red.8"
              : undefined,
        },
        {
          name: "Référence",
          value: preliminaryRequestReference,
        },
        {
          name: "Notes",
          value: preliminaryRequestNotes,
          isHidden:
            milestoneId !== PreliminaryRequestStep.PRELIMINARY_VALIDATION,
        },
        {
          name: "Raisons",
          dataType: "component",
          value: (
            <IssuesBadges issues={preliminaryRequestRequestedChangesReasons} />
          ),
          isHidden:
            preliminaryRequestRequestedChangesReasons.length === 0 ||
            (preliminaryRequestStep !== PreliminaryRequestStep.INCOMPLETE &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_SUBMITTED &&
              preliminaryRequestStep !== PreliminaryRequestStep.REJECTED &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_TO_SUBMIT &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_SUBMITTED),
        },
        {
          name: "En attente",
          value: preliminaryRequestAwaitedStakeholder,
          isHidden:
            preliminaryRequestStep !==
              PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER &&
            preliminaryRequestStep !==
              PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER,
        },
      ]}
    />
  )
}
