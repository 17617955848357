import { useUser } from "@clerk/clerk-react"
import {
  Burger,
  Drawer,
  Divider,
  Avatar,
  Group,
  AppShell,
  Stack,
  Image,
} from "@mantine/core"
import { useMediaQuery, useDisclosure } from "@mantine/hooks"
import { Link } from "react-router-dom"

import entoolLogo from "@ensol/entool/assets/entoolLogo.svg"
import { devices } from "@ensol/entool/styles/theme"

import { Menu } from "./Menu"

export const Navbar = () => {
  const isSmallScreen = useMediaQuery(devices.md)
  const [isNavbarOpen, { open: openNavBar, close: closeNavbar }] =
    useDisclosure(false)
  const { isLoaded, isSignedIn, user } = useUser()

  const backgroundColor =
    import.meta.env.VITE_ENVIRONMENT !== "production" ? "orange.0" : "white"

  if (isSmallScreen) {
    return (
      <AppShell.Header>
        <Group h="100%" px="lg" justify="space-between" bg={backgroundColor}>
          <Link to="/">
            <Image w="40" src={entoolLogo} alt="Logo Entool" />
          </Link>
          <Group h="100%" gap="0">
            <>
              <Burger mr="20" opened={isNavbarOpen} onClick={openNavBar} />
              <Drawer
                opened={isNavbarOpen}
                onClose={closeNavbar}
                size="230"
                position="right"
                styles={{ body: { padding: 0 } }}
              >
                <Menu closeNavbar={closeNavbar} />
              </Drawer>
            </>
            {isLoaded && isSignedIn && <Avatar src={user.imageUrl} />}
          </Group>
        </Group>
      </AppShell.Header>
    )
  }

  return (
    <AppShell.Navbar>
      <Stack
        h="100%"
        py="lg"
        justify="space-between"
        align="center"
        bg={backgroundColor}
      >
        <Stack align="center" p="8" w="100%">
          <Link to="/">
            <Image w="40" mb="16" src={entoolLogo} alt="Logo Entool" />
          </Link>
          <Menu />
          <Divider orientation="vertical" mr="16" />
        </Stack>
        {isLoaded && isSignedIn && <Avatar src={user.imageUrl} />}
      </Stack>
    </AppShell.Navbar>
  )
}
