import {
  Badge,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconAlertCircle } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"

import { ProjectWithAlerts } from "@ensol/types/project"

import { getInstallationAlert } from "@ensol/shared/entities/installations/alerts"
import { getFullName } from "@ensol/shared/entities/prospects"
import { formatDate } from "@ensol/shared/utils/format"

import { ConstructorAppLink } from "@ensol/entool/components/entities/Installation/ConstructorAppLink"
import { IgnoreAlertButton } from "@ensol/entool/pages/OpsProjectsAlerts/IgnoreAlert"
import { IntercomConversation } from "@ensol/entool/pages/OpsProjectsAlerts/IntercomConversation"
import { devices } from "@ensol/entool/styles/theme"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectWithAlerts
}

export const ProjectRow = ({ project }: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const theme = useMantineTheme()

  const {
    house,
    prospect,
    installer,
    technicalVisitInstaller,
    inverterType,
    externalPlantIdentifier,
    alerts,
  } = project

  const installers = getProjectInstallers({
    installer,
    technicalVisitInstaller,
  })

  return (
    <Card withBorder p="0">
      <Grid gutter={0}>
        <Grid.Col span={{ base: 12, sm: 2 }}>
          <Flex
            justify="space-between"
            h="100%"
            direction={isMobile ? "column" : "row"}
            align="flex-start"
            wrap="nowrap"
          >
            <Stack p="12" gap="4">
              <Group gap="4" align="center">
                <Text size="md" fw="500">
                  {getFullName(prospect)}
                </Text>
              </Group>
              <Text size="sm" c="gray.6">
                {house.postcode} {house.city}
              </Text>
              <Text size="sm" c="gray.6">
                {installers.length > 0 ? installers.join(", ") : "-"}
              </Text>
            </Stack>
            <Divider
              orientation={isMobile ? "horizontal" : "vertical"}
              w={isMobile ? "100%" : ""}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 10 }}>
          <Stack p="12" w="100%" gap="24">
            {_.orderBy(alerts, ({ date }) => date, "desc").map((alert) => (
              <Group
                key={alert.id}
                justify="space-between"
                align="flex-start"
                wrap="nowrap"
                gap="0"
              >
                <Flex
                  direction={isMobile ? "column" : "row"}
                  gap={isMobile ? 12 : 32}
                >
                  <Group gap="8" w="220">
                    <IconAlertCircle color={theme.colors.red[5]} size={20} />
                    <Stack gap="0">
                      <Text size="md">{formatDate(alert.date)}</Text>
                      <Text size="sm">
                        (Depuis {dayjs().diff(alert.date, "day")} jours)
                      </Text>
                    </Stack>
                  </Group>
                  <Stack gap="8">
                    <Badge
                      color="red"
                      variant="outline"
                      size={isMobile ? "sm" : "md"}
                    >
                      {getInstallationAlert(alert.type).name}
                    </Badge>
                    <IntercomConversation
                      alertId={alert.id}
                      intercomConversationId={alert.intercomConversationId}
                    />
                  </Stack>
                </Flex>
                <Group display={{ base: "none", md: "flex" }} gap="8">
                  <IgnoreAlertButton alertId={alert.id} />
                  {inverterType && externalPlantIdentifier && (
                    <ConstructorAppLink
                      inverterType={inverterType}
                      externalPlantIdentifier={externalPlantIdentifier}
                      size="xs"
                    />
                  )}
                </Group>
              </Group>
            ))}
          </Stack>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
