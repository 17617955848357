import { Group, MantineStyleProps, Select, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { ReactNode } from "react"

import { Team } from "@ensol/shared/entities/users"

import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { useSuppliersOptions } from "@ensol/entool/queries/suppliers"
import {
  PROJECT_SEARCHABLE_PROPS,
  useProjectsSearch,
} from "@ensol/entool/utils/projects/search"

type Props = {
  filteredProps: (keyof typeof PROJECT_SEARCHABLE_PROPS)[]
  children?: ReactNode
} & MantineStyleProps

export const ProjectSearch = ({ filteredProps, children, ...props }: Props) => {
  const installersOptions = useInstallersOptions()
  const suppliersOptions = useSuppliersOptions()

  const {
    search,
    setSearch,
    installerId,
    setInstallerId,
    supplierId,
    setSupplierId,
    salesOwnerId,
    setSalesOwnerId,
    operationsOwnerId,
    setOperationsOwnerId,
    adminOwnerId,
    setAdminOwnerId,
  } = useProjectsSearch()

  return (
    <Group
      justify="space-between"
      align="flex-end"
      mb={{ base: 12, md: 24 }}
      style={{ flexWrap: "nowrap" }}
      {...props}
    >
      <TextInput
        w="100%"
        leftSectionPointerEvents="none"
        leftSection={<IconSearch />}
        placeholder="Rechercher un projet par nom, email, téléphone, ou adresse"
        onChange={_.debounce((e) => setSearch(e.target.value), 250)}
        defaultValue={search}
      />
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.salesOwnerId) && (
        <UserSelect
          w="200"
          display={{ base: "none", md: "block" }}
          label="Responsable AE"
          value={salesOwnerId}
          teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
          withDefault={false}
          onChange={setSalesOwnerId}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.operationsOwnerId) && (
        <UserSelect
          w="200"
          display={{ base: "none", md: "block" }}
          label="Responsable Ops"
          value={operationsOwnerId}
          teams={[Team.OPERATIONS]}
          withDefault={false}
          onChange={setOperationsOwnerId}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.adminOwnerId) && (
        <UserSelect
          w="200"
          display={{ base: "none", md: "block" }}
          label="Responsable Admin"
          value={adminOwnerId}
          teams={[Team.ADMIN]}
          withDefault={false}
          onChange={(val) => {
            setAdminOwnerId(val)
          }}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.installerId) && (
        <Select
          w="150"
          display={{ base: "none", md: "block" }}
          label="Installateur"
          data={installersOptions}
          value={installerId}
          onChange={setInstallerId}
          style={{ flexShrink: 0 }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.supplierId) && (
        <Select
          w="150"
          display={{ base: "none", md: "block" }}
          label="Fournisseur"
          data={suppliersOptions}
          value={supplierId}
          onChange={setSupplierId}
          style={{ flexShrink: 0 }}
          clearable
        />
      )}
      {children}
    </Group>
  )
}
