import { transformSchemaForApi } from "@ensol/types/forms/utils"
import * as z from "zod"

export const depositToSendStepFormSchema = z.object({
  paymentNotes: z.string().optional(),
  paymentHoldEndDate: z.date().nullable().optional(),
})

export type DepositToSendStepInput = z.infer<typeof depositToSendStepFormSchema>

export const postDepositStepsFormSchema = z.object({
  paymentNotes: z.string().optional(),
})

export type PostDepositStepInput = z.infer<typeof postDepositStepsFormSchema>

export type PaymentStepInput = DepositToSendStepInput | PostDepositStepInput

export const paymentFormSchema = transformSchemaForApi(
  depositToSendStepFormSchema.merge(postDepositStepsFormSchema),
)
