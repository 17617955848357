import { Avatar, Group, Text } from "@mantine/core"
import { IconUserSquareRounded } from "@tabler/icons-react"

import { UsersResponses } from "@ensol/types/endpoints/users"

import { Tooltip } from "@ensol/entool/components/Tooltip"

type Props = {
  role: string
} & UsersResponses.User

export const Owner = ({ role, avatarUrl, firstName, lastName }: Props) => (
  <Tooltip
    label={
      <Group gap="4">
        <IconUserSquareRounded size={18} />
        <Text>
          {role}: {firstName} {lastName}
        </Text>
      </Group>
    }
  >
    <Avatar src={avatarUrl} size="24" />
  </Tooltip>
)
