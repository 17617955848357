import {
  Stack,
  Divider,
  Group,
  Title,
  TitleProps,
  TitleOrder,
  Collapse,
  Button,
  StackProps,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconChevronUp, TablerIcon } from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  title: string
  Actions?: ReactNode
  titleOrder?: TitleOrder
  icon?: TablerIcon
  iconColor?: string
  isCollapsible?: boolean
  isCollapsed?: boolean
  children?: ReactNode
} & StackProps

export const Section = ({
  title,
  Actions,
  icon: Icon,
  iconColor,
  titleOrder = 2,
  isCollapsible,
  isCollapsed,
  children,
  ...props
}: Props) => {
  const [opened, { toggle }] = useDisclosure(!isCollapsed)

  return (
    <Stack w="100%" mb={{ base: 16, md: 32 }} {...props}>
      <Group justify="space-between">
        <Button onClick={toggle} variant="transparent" color="black" p="0">
          <Group gap={8}>
            {Icon && <Icon size={20} color={iconColor} />}
            <Title order={titleOrder}>{title}</Title>
            {isCollapsible && (
              <>{opened ? <IconChevronUp /> : <IconChevronDown />}</>
            )}
          </Group>
        </Button>
        {Actions}
      </Group>
      <Divider />
      {isCollapsible ? <Collapse in={opened}>{children}</Collapse> : children}
    </Stack>
  )
}

const SectionTitle = (props: TitleProps) => (
  <Title {...props} order={4} mb={16} />
)

Section.Title = SectionTitle
