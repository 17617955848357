import { Flex, Group, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { ReactNode } from "react"

import { OptionType, Option } from "@ensol/entool/utils/form/radio"

type Props<T extends OptionType> = {
  options: Option<T>[]
  value?: T
  onChange: (value: T | null) => void
  disabled?: boolean
  nullable?: boolean
  error?: string | ReactNode
}

type OptionProps = {
  label: ReactNode
  subtitle?: string | ReactNode
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  dataTest?: string
}

export const RadioButton = ({
  label,
  subtitle,
  isSelected,
  isDisabled,
  onClick,
  dataTest,
}: OptionProps) => {
  return (
    <Stack
      p={8}
      gap={4}
      align="flex-end"
      bg={isSelected ? "blue.1" : "white"}
      onClick={!isDisabled ? onClick : undefined}
      data-test={dataTest}
      style={(theme) => ({
        borderRadius: 8,
        border: `1px solid ${
          !isDisabled && isSelected
            ? theme.colors.blue[5]
            : theme.colors.gray[2]
        }`,
        cursor: isDisabled ? "not-allowed" : "pointer",
      })}
    >
      {typeof label === "string" ? (
        <Text
          size="sm"
          c={isDisabled ? "gray.5" : isSelected ? "blue.7" : "gray.7"}
          fw={600}
        >
          {label}
        </Text>
      ) : (
        <Flex
          c={isDisabled ? "gray.5" : isSelected ? "blue.7" : "gray.7"}
          w="100%"
          h="100%"
        >
          {label}
        </Flex>
      )}
      {subtitle && (
        <Text
          size="sm"
          c={isDisabled ? "red.4" : isSelected ? "blue.5" : "gray.5"}
        >
          {subtitle}
        </Text>
      )}
    </Stack>
  )
}

export const RadioGroup = <T extends OptionType>({
  options,
  value,
  onChange,
  disabled = false,
  nullable = false,
  error,
}: Props<T>) => (
  <Group>
    <Stack gap="4" align="flex-end">
      <Group gap="8" justify="flex-end">
        {options.map((option) => (
          <RadioButton
            key={option.value.toString()}
            label={option.label}
            subtitle={option.subtitle}
            isSelected={option.value === value}
            isDisabled={disabled || !!option.disabled}
            dataTest={_.camelCase(option.value.toString())}
            onClick={() =>
              onChange(
                value === option.value
                  ? nullable
                    ? null
                    : option.value
                  : option.value,
              )
            }
          />
        ))}
      </Group>
      {error && (
        <Text c="red" size="xs">
          {error}
        </Text>
      )}
    </Stack>
  </Group>
)
