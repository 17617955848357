import { useForm, zodResolver } from "@mantine/form"
import dayjs from "dayjs"
import _ from "lodash"
import { useCallback, useEffect, useState } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  ProjectNoteInput,
  projectNoteSchema,
} from "@ensol/types/forms/projects/note"

import {
  useGetProjectNotesQuery,
  useAddProjectNoteMutation,
} from "@ensol/entool/queries/projects"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"

export const useProjectNotes = (projectId: string) => {
  const notesQuery = useGetProjectNotesQuery(projectId)
  const connectedUser = useAuthenticatedUser()
  const [viewport, setViewport] = useState<HTMLDivElement | null>(null)

  const noteForm = useForm<ProjectNoteInput>({
    validate: zodResolver(projectNoteSchema),
    initialValues: {
      content: "",
      userId: connectedUser?.id ?? "",
    },
  })

  const scrollToBottom = useCallback(() => {
    viewport?.scrollTo({
      top: viewport?.scrollHeight,
      behavior: "smooth",
    })
  }, [viewport])

  useEffect(() => {
    scrollToBottom()
  }, [scrollToBottom, notesQuery.data])

  const { mutateAsync: addProjectNotes, isPending: isAddNoteLoading } =
    useAddProjectNoteMutation(projectId)

  const onSubmit = async (content: string) => {
    noteForm.validate()
    if (connectedUser) {
      await addProjectNotes({
        content,
        userId: connectedUser.id,
      })
      noteForm.reset()
    }
  }

  const groupNotesByRelativeTime = (notes: ProjectResponses.ProjectNote[]) => {
    return _.groupBy(notes, (note) => {
      const date = dayjs(note.createdAt)
      if (date.isSame(dayjs(), "day")) {
        return "Aujourd'hui"
      } else if (date.isSame(dayjs().subtract(1, "day"), "day")) {
        return "Hier"
      } else if (date.isAfter(dayjs().subtract(1, "week"))) {
        return "Cette semaine"
      } else if (date.isAfter(dayjs().subtract(1, "month"))) {
        return "Ce mois-ci"
      } else {
        return "Plus d'un mois"
      }
    })
  }

  return {
    groupNotesByRelativeTime,
    notesQuery,
    isAddNoteLoading,
    onSubmit,
    setViewport,
    noteForm,
  }
}
