import { ProjectEventStatus } from "@ensol/shared/entities/projects/events"

export const EVENT_STATUSES_COLORS: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: "var(--mantine-color-blue-3)",
  [ProjectEventStatus.TO_SCHEDULE]: "var(--mantine-color-orange-5)",
  [ProjectEventStatus.SCHEDULED]: "var(--mantine-color-blue-5)",
  [ProjectEventStatus.REJECTED]: "var(--mantine-color-red-5)",
  [ProjectEventStatus.COMPLETED]: "var(--mantine-color-green-8)",
}

export const EXTERNAL_EVENT_COLOR = "black"

export const EVENT_WARNING_BORDER_COLOR = "var(--mantine-color-red-5)"

export const EVENT_STATUSES_NAMES: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: "Date proposée",
  [ProjectEventStatus.TO_SCHEDULE]: "Évènement prêt à planifier",
  [ProjectEventStatus.SCHEDULED]: "Évènement prévu",
  [ProjectEventStatus.REJECTED]: "Invitation refusée",
  [ProjectEventStatus.COMPLETED]: "Évènement terminé",
}
