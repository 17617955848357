import { Badge, Group, useMantineTheme } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconClock } from "@tabler/icons-react"
import dayjs from "dayjs"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

import { KeyFigure } from "@ensol/entool/pages/OpsProjectsOverview/Row/KeyFigure"
import { Owner } from "@ensol/entool/pages/OpsProjectsOverview/Row/Owner"
import { ProjectStatusConfig } from "@ensol/entool/pages/OpsProjectsOverview/config"
import { devices } from "@ensol/entool/styles/theme"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectResponses.ProjectOverview
  getReferenceDates: ProjectStatusConfig["getReferenceDates"]
}

export const ProjectKeyFigures = ({ project, getReferenceDates }: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const theme = useMantineTheme()
  const referenceDates = getReferenceDates(project)
  const installers = getProjectInstallers(project)
  const daysSinceLastEvent = dayjs().diff(project.lastStatusEventDate, "day")

  return (
    <Group gap={isMobile ? 8 : 18} align="flex-start">
      {referenceDates.map(({ date, label, status }) => {
        if (date === null) {
          return null
        }
        return (
          <KeyFigure
            key={label}
            label={`${status === "warning" ? "⚠ " : ""}${label}`}
            color={
              status === "warning"
                ? "red.8"
                : status === "success"
                  ? "green.8"
                  : undefined
            }
            w="120"
          >
            {formatDate(date, SHORT_DISPLAY_DATE_FORMAT) ?? "-"}
          </KeyFigure>
        )
      })}
      <KeyFigure label="Installateurs" w="100">
        {installers.length > 0 ? installers.join(", ") : "-"}
      </KeyFigure>
      <KeyFigure label="Temps écoulé" w="100">
        <Badge
          color="dark.6"
          variant="light"
          size="xs"
          styles={{
            label: { display: "flex", alignItems: "center", gap: 4 },
          }}
        >
          <IconClock color={theme.colors.dark[4]} size={16} />{" "}
          {daysSinceLastEvent} jour{daysSinceLastEvent > 1 ? "s" : ""}
        </Badge>
      </KeyFigure>
      <KeyFigure label="Responsables" w="120">
        <Group gap="2">
          {project.operationsOwner && (
            <Owner role="Responsable Ops" {...project.operationsOwner} />
          )}
          {project.salesOwner && (
            <Owner role="Responsable Sales" {...project.salesOwner} />
          )}
          {project.adminOwner && (
            <Owner role="Responsable Admin" {...project.adminOwner} />
          )}
          {project.technicalExpert && (
            <Owner role="Expert Technique" {...project.technicalExpert} />
          )}
        </Group>
      </KeyFigure>
    </Group>
  )
}
