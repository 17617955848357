import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  ProductCosts,
  withMargin,
} from "@ensol/shared/entities/installations/costs"
import { computeExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import { BatteryType, getBattery } from "@ensol/shared/material/batteries"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const BATTERY_VAT_RATE = 0.2

// MATERIAL COSTS {€)
// Build kits
const ENPHASE_ACCESSORIES_PRICE = 421.2 // €
const HUAWEI_ACCESSORIES_PRICE = 71.52 // €

// WORKFORCE COSTS {€)
export const BATTERY_ADMIN_FEE_COST = 149 // €

export const computeBatteryUnitPrice = (batteryType: BatteryType): number => {
  const battery = getBattery(batteryType)
  return withMargin(battery.price)
}

export const computeBatteryAccessoriesPrice = (
  batteryType: BatteryType,
): number => {
  const battery = getBattery(batteryType)

  if (battery.brand === "Enphase") {
    return withMargin(ENPHASE_ACCESSORIES_PRICE)
  }

  if (battery.brand === "Huawei") {
    return withMargin(HUAWEI_ACCESSORIES_PRICE)
  }
  return 0
}

const computeBatteryMaterialCost = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): number =>
  computeBatteryUnitPrice(batteryInstallation.type) +
  computeBatteryAccessoriesPrice(batteryInstallation.type)

export const computeBatteryWorkforceCost = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): number => {
  const battery = getBattery(batteryInstallation.type)

  return withMargin(battery.installationCost)
}

const computeBatteryInstallationCost = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): number => {
  const materialCostHT = computeBatteryMaterialCost(batteryInstallation)
  const extraWorksCostHT = computeExtraWorksCosts(batteryInstallation)
  const workforceCostHT = computeBatteryWorkforceCost(batteryInstallation)

  const totalCostHT =
    materialCostHT + extraWorksCostHT + workforceCostHT + BATTERY_ADMIN_FEE_COST

  return roundDecimalNumber(totalCostHT * (1 + BATTERY_VAT_RATE))
}

type AllCostsInput = {
  batteryInstallation: InstallationsResponses.BatteryInstallation
}

export const computeAllBatteryCosts = ({
  batteryInstallation,
}: AllCostsInput): ProductCosts => {
  const installationCost = computeBatteryInstallationCost(batteryInstallation)

  const installationCostBeforeTax = roundDecimalNumber(
    installationCost / (1 + BATTERY_VAT_RATE),
  )

  return {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies: installationCost,
    vatRate: BATTERY_VAT_RATE,
  }
}
