import {
  ActionIcon,
  Group,
  NumberInput,
  Stack,
  Tooltip,
  Text,
  Loader,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconLink, IconLinkOff } from "@tabler/icons-react"
import { useEffect, useMemo, useState } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { ConsumptionDataSource } from "@ensol/types/simulation"

import {
  computeMonthlyBill,
  computeYearlyConsumption,
  estimateDefaultEnergyPrice,
} from "@ensol/shared/entities/installations/energy"
import {
  formatCurrency,
  formatDate,
  formatNumber,
} from "@ensol/shared/utils/format"
import { computeTimeFrameDuration } from "@ensol/shared/utils/timeFrame"

import { InfoBox } from "@ensol/entool/components/InfoBox"
import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import {
  useBillConsumption,
  useRealDataConsumption,
  useSwitchgridConsumption,
} from "@ensol/entool/queries/energy"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { CONSUMPTION_DATA_SOURCE_OPTIONS } from "@ensol/entool/utils/installations/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  masterInstallationId?: string
}

export const ConsumptionParameters = <
  Values extends {
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyBill: number
  },
>({
  form,
  house,
  masterInstallationId,
}: Props<Values>) => {
  const [linkBillAndConsumption, setLinkBillAndConsumption] = useState(
    form.values.consumptionDataSource !== ConsumptionDataSource.SWITCHGRID,
  )

  const billConsumptionQuery = useBillConsumption(form.values.yearlyConsumption)
  const switchgridConsumptionQuery = useSwitchgridConsumption(house)
  const realDataConsumptionQuery = useRealDataConsumption(
    house,
    masterInstallationId,
  )

  const consumptionOptionsQueries = useMemo(
    () => ({
      [ConsumptionDataSource.BILL]: {
        data: billConsumptionQuery.data,
        isLoading: billConsumptionQuery.isLoading,
      },
      [ConsumptionDataSource.SWITCHGRID]: {
        data: switchgridConsumptionQuery.data,
        isLoading: switchgridConsumptionQuery.isLoading,
      },
      [ConsumptionDataSource.REAL]: {
        data: realDataConsumptionQuery.data,
        isLoading: realDataConsumptionQuery.isLoading,
      },
    }),
    [
      billConsumptionQuery.data,
      billConsumptionQuery.isLoading,
      realDataConsumptionQuery.data,
      realDataConsumptionQuery.isLoading,
      switchgridConsumptionQuery.data,
      switchgridConsumptionQuery.isLoading,
    ],
  )

  const enedisDataTimeFrame =
    switchgridConsumptionQuery.data?.enedisDataTimeFrame ?? null
  const { consumptionDataSource, yearlyConsumption, monthlyBill } = form.values

  const consumptionDataSourceOptions = useMemo(() => {
    return CONSUMPTION_DATA_SOURCE_OPTIONS.map((option) => {
      const consumptionQuery = consumptionOptionsQueries[option.value]
      return {
        ...option,
        disabled: !consumptionQuery.isLoading && !consumptionQuery.data,
        subtitle: consumptionQuery.isLoading ? (
          <Loader size={24} type="dots" />
        ) : !consumptionQuery.data ? (
          "Indisponible"
        ) : (
          `Consommation: ${formatNumber(consumptionQuery.data.yearlyConsumption)} kWh/an`
        ),
      }
    })
  }, [consumptionOptionsQueries])

  useEffect(() => {
    if (
      findSelectedOption(consumptionDataSource, consumptionDataSourceOptions)
        ?.disabled
    ) {
      form.setFieldError("consumptionDataSource", "Source invalide")
    } else {
      form.clearFieldError("consumptionDataSource")
    }
  }, [form, consumptionDataSource, consumptionDataSourceOptions])

  return (
    <>
      <LegacyField name="Source des données de consommation">
        <RadioGroup
          options={consumptionDataSourceOptions}
          value={consumptionDataSource}
          onChange={(value) => {
            form.getInputProps("consumptionDataSource").onChange(value)
            if (value) {
              form
                .getInputProps("yearlyConsumption")
                .onChange(
                  consumptionOptionsQueries[value].data?.yearlyConsumption,
                )
            }

            if (value === ConsumptionDataSource.BILL) {
              setLinkBillAndConsumption(true)
            } else {
              setLinkBillAndConsumption(false)
            }
          }}
        />
        {consumptionDataSource === ConsumptionDataSource.SWITCHGRID &&
          enedisDataTimeFrame !== null &&
          computeTimeFrameDuration(enedisDataTimeFrame, "year") < 1 && (
            <InfoBox
              message={`Il y'a moins d'un an de données Enedis disponibles (${formatDate(enedisDataTimeFrame.startDate, "MMMM YYYY")} à ${formatDate(enedisDataTimeFrame.endDate, "MMMM YYYY")}). Nous utilisons des données calculées à partir d'un profil moyen pour compléter.`}
              color="orange"
            />
          )}
      </LegacyField>
      <LegacyField name="Consommation / Facture">
        <Stack align="flex-end" gap={4}>
          <Group align="center" gap={8}>
            <NumberInput
              w={150}
              min={0}
              step={1000}
              ml="auto"
              suffix=" kWh/an"
              disabled={
                consumptionDataSource === ConsumptionDataSource.SWITCHGRID
              }
              data-test="yearlyConsumption"
              {...form.getInputProps("yearlyConsumption")}
              onChange={(value) => {
                form.getInputProps("yearlyConsumption").onChange(+value)
                if (linkBillAndConsumption) {
                  form
                    .getInputProps("monthlyBill")
                    .onChange(computeMonthlyBill(+value))
                }
              }}
            />
            <Tooltip
              label={`Lier consommation & facture au prix EDF moyen: ${estimateDefaultEnergyPrice(
                new Date().getFullYear(),
              )} €/kWh`}
            >
              <ActionIcon
                size="sm"
                variant="subtle"
                color="gray"
                disabled={
                  consumptionDataSource === ConsumptionDataSource.SWITCHGRID
                }
                onClick={() => setLinkBillAndConsumption((linked) => !linked)}
              >
                {linkBillAndConsumption ? <IconLink /> : <IconLinkOff />}
              </ActionIcon>
            </Tooltip>
            <NumberInput
              w={150}
              min={0}
              step={50}
              ml="auto"
              suffix=" €/mois"
              {...form.getInputProps("monthlyBill")}
              onChange={(value) => {
                form.getInputProps("monthlyBill").onChange(value)
                if (linkBillAndConsumption) {
                  form
                    .getInputProps("yearlyConsumption")
                    .onChange(computeYearlyConsumption(+value))
                }
              }}
            />
          </Group>
          {yearlyConsumption > 0 && (
            <Text size="sm" c="gray.7">
              Prix moyen:{" "}
              {formatCurrency((12 * monthlyBill) / yearlyConsumption)}
              /kWh
            </Text>
          )}
        </Stack>
      </LegacyField>
    </>
  )
}
