import { useMantineTheme } from "@mantine/core"
import { IconBellRinging, IconChecklist, IconEye } from "@tabler/icons-react"

import { ProjectTaskType } from "@ensol/types/project"

type Props = {
  type: ProjectTaskType
  color: string
}

export const ProjectTaskIcon = ({ type, color }: Props) => {
  const theme = useMantineTheme()

  switch (type) {
    case ProjectTaskType.TODO:
      return <IconChecklist color={theme.colors[color][5]} size={16} />
    case ProjectTaskType.REMINDER:
      return <IconBellRinging color={theme.colors[color][5]} size={13} />
    case ProjectTaskType.MONITORING:
      return <IconEye color={theme.colors[color][5]} size={16} />
    default:
      return null
  }
}
