import { InstallerResponses } from "@ensol/types/endpoints/installers"
import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  ProjectEventStatus,
  ProjectEventType,
} from "@ensol/shared/entities/projects/events"

import { PublicHolidaysEvents } from "@ensol/entool/queries/calendars"
import {
  EVENT_STATUSES_COLORS,
  EVENT_WARNING_BORDER_COLOR,
  EXTERNAL_EVENT_COLOR,
} from "@ensol/entool/utils/projects/events"

export const formatProjectsEvents = (
  projectsEventsResponse: ProjectResponses.ProjectsEvents,
) =>
  projectsEventsResponse.events.map(
    ({ type, start, end, status, warning, metadata }) => ({
      id: `${metadata.projectId}-${type}`,
      title: `${warning ? "⚠️ " : ""}${metadata.clientName}${metadata.installerName ? ` , ${metadata.installerName}` : ""}`,
      start,
      end,
      allDay: type === ProjectEventType.INSTALLATION,
      textColor: status === ProjectEventStatus.PROPOSAL ? "black" : "white",
      backgroundColor: EVENT_STATUSES_COLORS[status],
      borderColor: warning
        ? EVENT_WARNING_BORDER_COLOR
        : EVENT_STATUSES_COLORS[status],
      startEditable:
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      durationEditable:
        type === ProjectEventType.INSTALLATION &&
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      extendedProps: { ...metadata, type, status, warning },
    }),
  )

export const formatInstallersEvents = ({
  installersEventsResponse,
}: {
  installersEventsResponse: InstallerResponses.InstallerEvent[]
}) =>
  installersEventsResponse.map(({ name, start, end, isAllDay }) => ({
    title: name,
    start,
    end,
    allDay: isAllDay,
    backgroundColor: "white",
    textColor: EXTERNAL_EVENT_COLOR,
    borderColor: EXTERNAL_EVENT_COLOR,
  }))

export const formatPublicHolidayEvents = (
  publicHolidaysResponse: PublicHolidaysEvents,
) =>
  Object.entries(publicHolidaysResponse).map(([date, name]) => ({
    title: name,
    date: new Date(date),
    allDay: true,
    backgroundColor: "white",
    textColor: EXTERNAL_EVENT_COLOR,
    borderColor: EXTERNAL_EVENT_COLOR,
  }))
