import { ActionIcon, Modal, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconSettings } from "@tabler/icons-react"
import { useLocalStorage } from "@uidotdev/usehooks"

import { Checkbox } from "@ensol/entool/components/form/Checkbox"

export const DISPLAY_SWITCHGRID_SECTION_SETTING_KEY =
  "isSwitchgridSectionDisplayed"

export const Settings = () => {
  const [
    isConfigModalOpen,
    { open: openConfigModalOpen, close: closeConfigModalOpen },
  ] = useDisclosure(false)

  const [isSwitchgridSectionDisplayed, setIsSwitchgridSectionDisplayed] =
    useLocalStorage(DISPLAY_SWITCHGRID_SECTION_SETTING_KEY, false)

  return (
    <>
      <ActionIcon
        aria-label="Configurer"
        size="lg"
        variant="white"
        onClick={openConfigModalOpen}
      >
        <IconSettings color="black" />
      </ActionIcon>
      <Modal
        size={600}
        radius="sm"
        padding="lg"
        opened={isConfigModalOpen}
        onClose={closeConfigModalOpen}
        title={<Text fw="bold">Configuration</Text>}
      >
        <Checkbox
          checked={isSwitchgridSectionDisplayed}
          onChange={(event) =>
            setIsSwitchgridSectionDisplayed(event.currentTarget.checked)
          }
          label='Afficher la section "Autorisation données réelles"'
        />
      </Modal>
    </>
  )
}
