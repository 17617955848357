import {
  Group,
  Stack,
  Switch,
  Tabs,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconAlertCircle } from "@tabler/icons-react"
import { useState } from "react"
import { Helmet } from "react-helmet-async"

import { ProjectAccordion } from "@ensol/entool/components/entities/Project/ProjectAccordion"
import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useProjectDrawer } from "@ensol/entool/pages/OpsProjectsOverview/useProjectDrawer"
import { useProjectsOverview } from "@ensol/entool/pages/OpsProjectsOverview/useProjectsOverview"
import { devices } from "@ensol/entool/styles/theme"
import { getZIndex } from "@ensol/entool/styles/z-index"
import { useTabsNavigation } from "@ensol/entool/utils/helpers/useTabsNavigation"
import { PROJECT_SEARCHABLE_PROPS } from "@ensol/entool/utils/projects/search"
import {
  getProjectStatusLabel,
  PROJECT_STATUSES,
} from "@ensol/entool/utils/projects/statuses"

import { ProjectDrawer } from "./ProjectDrawer"
import { ProjectRow } from "./Row"

const ALL_PROJECTS_TAB_ID = "ALL"

const PROJECTS_OVERVIEW_TABS = [
  { id: ALL_PROJECTS_TAB_ID, label: "Tous" },
  ...PROJECT_STATUSES,
]

export const OpsProjectsOverview = () => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(devices.sm)

  const { currentTab, handleTabChange } = useTabsNavigation(
    PROJECTS_OVERVIEW_TABS,
  )
  const {
    selectedProject,
    activeDrawerTab,
    setActiveDrawerTab,
    openDrawer,
    closeDrawer,
  } = useProjectDrawer()

  const [showOnlyActionNeeded, setShowOnlyActionNeeded] = useState(false)
  const projectsQuery = useProjectsOverview(currentTab, showOnlyActionNeeded)

  return (
    <PageLayout
      title="Tous les projets"
      contentContainerProps={{ px: 20, mih: "unset" }}
    >
      <Helmet>
        <title>Tous les projets | Entool</title>
      </Helmet>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        color="gray.8"
        styles={{
          root: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
          },
        }}
      >
        <Stack
          pos="sticky"
          top="0"
          bg="white"
          style={{ zIndex: getZIndex("stickyHeader") }}
          gap="12"
        >
          {!isMobile && (
            <Tabs.List>
              {PROJECTS_OVERVIEW_TABS.map(({ id, label }) => (
                <Tabs.Tab key={id} value={id}>
                  {label}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          )}
          <ProjectSearch
            filteredProps={[
              PROJECT_SEARCHABLE_PROPS.search,
              PROJECT_SEARCHABLE_PROPS.operationsOwnerId,
              PROJECT_SEARCHABLE_PROPS.installerId,
            ]}
          >
            {!isMobile && (
              <Group gap={4} style={{ flexShrink: 0 }} pb="8">
                <Switch
                  checked={showOnlyActionNeeded}
                  onChange={(event) =>
                    setShowOnlyActionNeeded(event.currentTarget.checked)
                  }
                />
                <IconAlertCircle color={theme.colors.red[5]} size={20} />
                <Text size="sm" fw="500">
                  Actions uniquement
                </Text>
              </Group>
            )}
          </ProjectSearch>
        </Stack>
        <QueryWrapper
          query={projectsQuery}
          disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
        >
          {({ data }) => {
            return (
              <ProjectAccordion
                data={data.map((projectsOverview) => ({
                  items: projectsOverview.projects,
                  id: projectsOverview.type,
                  title: getProjectStatusLabel(projectsOverview.type),
                }))}
                render={(items) =>
                  items.map((project) => (
                    <ProjectRow
                      key={project.id}
                      project={project}
                      isDrawerOpen={selectedProject?.id === project.id}
                      openDrawer={openDrawer}
                    />
                  ))
                }
              />
            )
          }}
        </QueryWrapper>
      </Tabs>
      <ProjectDrawer
        project={selectedProject}
        activeTab={activeDrawerTab}
        setActiveTab={setActiveDrawerTab}
        onClose={closeDrawer}
      />
    </PageLayout>
  )
}
