import { Textarea, SimpleGrid, Button } from "@mantine/core"

import { EdfOaStepInput } from "@ensol/types/forms/projects/edfOa"

import { getProspectFileName } from "@ensol/shared/utils/files"

import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useCreateEdfOaCertificateMutation } from "@ensol/entool/queries/projects"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const EdfOaForm = <Input extends EdfOaStepInput>({
  schema,
  initialValues,
  project,
  processId,
  actions,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId,
  })
  const { mutateAsync: createCertificate, isPending: isCertificateLoading } =
    useCreateEdfOaCertificateMutation(project.id)

  const { prospect } = project.installation.house

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(form.values)}
    >
      <SimpleGrid cols={2} spacing="32" mt="16">
        <FormField initialValues={initialValues} propertyName="edfOaNotes">
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("edfOaNotes")}
          />
        </FormField>
        <FormField
          initialValues={initialValues}
          propertyName="edfOaCertificatePath"
          isRequired
        >
          <Button
            onClick={async () => {
              const { edfOaCertificatePath } = await createCertificate()
              form
                .getInputProps("edfOaCertificatePath")
                .onChange(edfOaCertificatePath)
            }}
            loading={isCertificateLoading}
            disabled={project.edfOaCertificatePath !== null}
            w={FILE_THUMBNAIL_WIDTH_IN_PX}
          >
            Générer l&apos;attestation BTA
          </Button>
          <FileField
            previewProps={{
              fileName: getProspectFileName("attestation-bta", prospect),
            }}
            inputProps={{ accept: ["application/pdf"] }}
            {...form.getInputProps("edfOaCertificatePath")}
          />
        </FormField>
      </SimpleGrid>
    </StepActions>
  )
}
