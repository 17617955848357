import { Text } from "@mantine/core"
import dayjs from "dayjs"

import { TechnicalVisitStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { CheckBadge } from "../components/CheckBadge"
import { InfoComponentProps } from "../config"

export const TechnicalVisitInfo = ({
  project: {
    technicalVisitInstaller,
    technicalVisitStep,
    technicalVisitStartDate,
    isTechnicalVisitReady,
    technicalVisitSchedulingIssues,
    technicalVisitSchedulingHoldEndDate,
    technicalVisitLastContactDate,
  },
}: InfoComponentProps) => (
  <ProcessInfos
    infos={[
      {
        name: "Installateur",
        value: technicalVisitInstaller?.name,
      },
      {
        name: "Date visite",
        value: formatDate(technicalVisitStartDate, SHORT_DISPLAY_DATE_FORMAT),
        isHidden: technicalVisitStep !== TechnicalVisitStep.SCHEDULED,
      },
      {
        name: "Visite prête",
        dataType: "component",
        value: <CheckBadge isChecked={isTechnicalVisitReady} />,
        isHidden: technicalVisitStep !== TechnicalVisitStep.SCHEDULED,
      },
      {
        name: "Problèmes",
        dataType: "component",
        value:
          technicalVisitSchedulingIssues.length > 0 ? (
            <IssuesBadges issues={technicalVisitSchedulingIssues} />
          ) : (
            <Text size="sm" fw={500}>
              -
            </Text>
          ),
        isHidden:
          technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
          technicalVisitStep !== TechnicalVisitStep.CANNOT_SCHEDULE,
      },
      {
        name: "À rappeler le",
        value: formatDate(
          technicalVisitSchedulingHoldEndDate,
          SHORT_DISPLAY_DATE_FORMAT,
        ),
        isHidden:
          technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
          technicalVisitStep !== TechnicalVisitStep.CANNOT_SCHEDULE,
        color: dayjs().isAfter(technicalVisitSchedulingHoldEndDate)
          ? "red.8"
          : undefined,
      },
      {
        name: "Dernier contact",
        value: formatDate(
          technicalVisitLastContactDate,
          SHORT_DISPLAY_DATE_FORMAT,
        ),
        isHidden:
          technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
          technicalVisitStep !== TechnicalVisitStep.CANNOT_SCHEDULE,
      },
    ]}
  />
)
