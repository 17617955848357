import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { Field } from "@ensol/entool/components/form/Field"

type Props = {
  project: ProjectResponses.Project
}

export const PaymentInfo = ({ project }: Props) => {
  return (
    project.salesVisitFinancingMethod && (
      <Field name="Méthode de financement" noBorder>
        <TextInfo value={project.salesVisitFinancingMethod} />
      </Field>
    )
  )
}
