import { Group, Input, Stack } from "@mantine/core"
import {
  IconAt,
  IconHomeCog,
  IconHomeMove,
  IconPhone,
  IconThumbUp,
  IconUser,
} from "@tabler/icons-react"
import { ChangeEvent } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import {
  ProjectStatus,
  getStatusIndex,
} from "@ensol/shared/entities/projects/statuses"
import { getFullName } from "@ensol/shared/entities/prospects"

import { Header } from "@ensol/entool/components/Header"
import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { Link } from "@ensol/entool/components/Link"
import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import {
  getMonthlyReportUrl,
  getSummaryUrl,
} from "@ensol/entool/utils/projects/reports"

type Props = {
  name: string
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void
  prospect: ProspectResponses.Prospect
  project?: InstallationsResponses.Installation["project"]
  slug?: string
}

export const INSTALLATION_HEADER_HEIGHT_IN_PX = 120

export const InstallationHeader = ({
  slug,
  name,
  onNameChange,
  prospect,
  project,
}: Props) => {
  const isProjectSigned =
    project !== undefined &&
    project !== null &&
    getStatusIndex(project.status) >= getStatusIndex(ProjectStatus.SIGNED)

  return (
    <Header
      backPath={`/prospects/${prospect.id}`}
      LeftComponent={
        <Stack w="100%" gap="4">
          <Input
            autoFocus
            miw="60dvw"
            variant="unstyled"
            placeholder="Nom de l'installation"
            styles={{ input: { fontSize: 28, fontWeight: 500 } }}
            value={name}
            onChange={onNameChange}
            data-test="installationName"
          />
          <Group>
            <HeaderInfos
              infos={[
                { Icon: IconUser, text: getFullName(prospect) },
                { Icon: IconAt, text: prospect.email },
                { Icon: IconPhone, text: prospect.phone },
              ]}
            />
            <HubspotLink
              hubspotContactId={prospect.hubspotContactId}
              variant="transparent"
              p="0"
            />
          </Group>
        </Stack>
      }
      RightComponent={
        <Stack align="flex-end" gap="8">
          {slug && (
            <Group wrap="nowrap">
              <Link
                label="Résumé client"
                href={getSummaryUrl(slug)}
                color="blue.8"
                isExternal
              />
              <Link
                label="Simulation rapport mois"
                href={getMonthlyReportUrl({
                  installationSlug: slug,
                  productionStartDate: new Date(),
                  isSimulated: true,
                })}
                color="blue.8"
                isExternal
              />
            </Group>
          )}
          <Group wrap="nowrap">
            {project && (
              <Link
                label="Visite commerciale"
                href={`/sales/${project.id}`}
                Icon={<IconHomeMove />}
              />
            )}
            {isProjectSigned && (
              <>
                <Link
                  label="Projet"
                  href={`/projects/${project.id}`}
                  Icon={<IconHomeCog />}
                />
                <Link
                  label="NPS"
                  href={`/feedbacks/${project.id}`}
                  Icon={<IconThumbUp />}
                />
              </>
            )}
          </Group>
        </Stack>
      }
      height={INSTALLATION_HEADER_HEIGHT_IN_PX}
    />
  )
}
