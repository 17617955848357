import {
  Table,
  Checkbox,
  Tooltip,
  Group,
  Menu,
  ActionIcon,
  rem,
  Text,
  Anchor,
} from "@mantine/core"
import {
  IconBoxMultiple,
  IconDotsVertical,
  IconTrash,
} from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { InstallationProducts } from "@ensol/entool/components/entities/Installation/InstallationProducts"
import { ProjectStatusBadge } from "@ensol/entool/components/entities/Installation/ProjectStatusBadge"
import {
  useDeleteInstallation,
  useDuplicateInstallation,
} from "@ensol/entool/queries/installations"
import { useBundleInstallationMutation } from "@ensol/entool/queries/simulations"
import { getSummaryUrl } from "@ensol/entool/utils/projects/reports"

type InstallationRowProps = {
  installation: ProspectResponses.ProspectDetails["houses"][number]["installations"][number]
  onLineClick: () => void
  canBundle: boolean
}

export const InstallationRow = ({
  installation,
  onLineClick,
  canBundle,
}: InstallationRowProps) => {
  const { mutateAsync: updateInstallationBundled } =
    useBundleInstallationMutation(installation.prospectId, installation.id)

  const { mutateAsync: deleteInstallation } = useDeleteInstallation(
    installation.prospectId,
    installation.id,
  )

  const { mutateAsync: duplicateInstallation } = useDuplicateInstallation(
    installation.prospectId,
    installation.id,
  )

  return (
    <Table.Tr
      key={installation.id}
      onClick={onLineClick}
      style={{ cursor: "pointer" }}
    >
      <Table.Td>
        <Text w={{ base: 400, xl: 500 }} truncate="end">
          {installation.name}
        </Text>
      </Table.Td>
      <Table.Td miw="150">
        <InstallationProducts installation={installation} />
      </Table.Td>
      <Table.Td>
        <ProjectStatusBadge status={installation.project?.status} />
      </Table.Td>
      <Table.Td>
        {formatDate(installation.createdAt, SHORT_DISPLAY_DATE_FORMAT)}
      </Table.Td>
      <Table.Td>
        <Tooltip
          disabled={canBundle}
          label="Le bundle doit contenir maximum 3 offres"
        >
          <Checkbox
            checked={installation.bundled}
            onChange={(event) =>
              updateInstallationBundled(event.target.checked)
            }
            onClick={(e) => e.stopPropagation()}
            disabled={!installation.bundled && !canBundle}
          />
        </Tooltip>
      </Table.Td>
      <Table.Td miw="130">
        <Group>
          <Anchor
            onClick={(e) => e.stopPropagation()}
            href={getSummaryUrl(installation.slug)}
            target="_blank"
          >
            Résumé
          </Anchor>
          <Menu>
            <Menu.Target>
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <IconDotsVertical aria-label="Menu" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={
                  <IconBoxMultiple
                    style={{ width: rem(14), height: rem(14) }}
                  />
                }
                onClick={async (event) => {
                  event.stopPropagation()
                  await duplicateInstallation()
                }}
              >
                Dupliquer
              </Menu.Item>
              <Menu.Item
                color="red"
                disabled={installation.bundled}
                leftSection={
                  <IconTrash style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={async (e) => {
                  e.stopPropagation()
                  await deleteInstallation()
                }}
              >
                Supprimer
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Table.Td>
    </Table.Tr>
  )
}
