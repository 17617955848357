import { useForm, zodResolver } from "@mantine/form"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { salesVisitFormSchema } from "@ensol/types/forms/projects/salesVisit"

import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export const useProjectForm = ({
  id,
  salesOwnerId,
  salesVisitPanelsLayoutPath,
  salesVisitK2ReportPath,
  salesVisitSuggestedLocationPhotos,
  salesVisitFinancingMethod,
  technicalVisitClientAvailabilities,
  technicalVisitSalesComments,
  electricityBillPath,
}: ProjectResponses.Project) => {
  const { mutateAsync: updateProject } = useUpdateProcessDataMutation(
    "sales-visit",
    id,
  )
  const debouncedUpdateProject = useDebounce(updateProject, 250)

  const projectForm = useForm({
    validateInputOnChange: true,
    validate: zodResolver(salesVisitFormSchema),
    onValuesChange: async (values) => debouncedUpdateProject(values),
    initialValues: {
      salesOwnerId,
      salesVisitPanelsLayoutPath,
      salesVisitK2ReportPath,
      salesVisitSuggestedLocationPhotos,
      salesVisitFinancingMethod,
      technicalVisitClientAvailabilities,
      technicalVisitSalesComments,
      electricityBillPath,
    },
  })

  return projectForm
}
