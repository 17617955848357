import {
  Divider,
  Stack,
  Title,
  Text,
  SimpleGrid,
  Group,
  ModalBaseProps,
} from "@mantine/core"
import { TablerIcon } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"
import { ProcessId } from "@ensol/shared/entities/projects/processes"

import { ProcessesMilestoneReport } from "@ensol/entool/components/entities/Project/ProcessView/MilestoneReport"
import { StepAction } from "@ensol/entool/components/entities/Project/StepActions/StepAction"
import {
  OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX,
  useNavbarDimensions,
} from "@ensol/entool/styles/constants"
import { getZIndex } from "@ensol/entool/styles/z-index"

import { EmailSender } from "./EmailSender"

export type StepActionsProps = {
  project: ProjectResponses.Project
  processId: ProcessId
  actions: StepActionProps[]
  emails?: { eventName: EmailEvent; label: string }[]
  relatedProcesses?: ProcessId[]
  validateStep?: () => void
  children?: React.ReactNode
}

export type CustomActionModalProps = {
  project: ProjectResponses.Project
  processId: ProcessId
} & ModalBaseProps

type BaseStepActionProps = {
  label?: string
  color?: string
  Icon?: TablerIcon
  validateAction?: (project: ProjectResponses.Project) => void
  onClick?: (project: ProjectResponses.Project) => void
}

type CustomStepActionProps = BaseStepActionProps & {
  action?: never
  CustomActionModal: React.FC<CustomActionModalProps>
  isStepCompletion?: never
  confirmationMessage?: never
}

type StandardStepActionProps = BaseStepActionProps & {
  action: string
  CustomActionModal?: never
  isStepCompletion?: boolean
  confirmationMessage?: string
}

export type StepActionProps = CustomStepActionProps | StandardStepActionProps

export type StepComponentProps = Pick<StepActionsProps, "project" | "processId">

export const StepActions = ({
  project,
  processId,
  actions,
  emails = [],
  relatedProcesses = [],
  validateStep,
  children,
}: StepActionsProps) => {
  const { navbarHeight } = useNavbarDimensions()

  return (
    <Stack>
      <Stack
        pos="sticky"
        top={OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX + navbarHeight}
        bg="white"
        pt="16"
        mb="4"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Group justify="space-between">
          <Group>
            <Title order={2} display="flex" style={{ gap: 8 }}>
              Étape en cours
            </Title>
            {emails.map(({ eventName, label }) => (
              <EmailSender
                key={eventName}
                eventName={eventName}
                project={project}
              >
                {label}
              </EmailSender>
            ))}
          </Group>
          <Group>
            {actions.map((action) => (
              <StepAction
                key={action.label}
                project={project}
                processId={processId}
                emails={emails}
                validateStep={validateStep}
                actionProps={action}
              />
            ))}
          </Group>
        </Group>
        <Divider />
      </Stack>
      {relatedProcesses.length > 0 && (
        <Stack
          p="28 16 16 16"
          mb="16"
          pos="relative"
          style={{
            borderRadius: 8,
            border: "1px solid var(--mantine-color-gray-4)",
          }}
        >
          <Text
            size="md"
            fs="italic"
            pos="absolute"
            p="0 4"
            top="-12px"
            bg="white"
          >
            Processus lié(s) à cette étape
          </Text>
          <SimpleGrid cols={2} spacing="16">
            <ProcessesMilestoneReport
              processes={relatedProcesses}
              project={project}
            />
          </SimpleGrid>
        </Stack>
      )}
      {children}
    </Stack>
  )
}
