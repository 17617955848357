import { useMantineTheme, Group, Text } from "@mantine/core"
import {
  IconAlertCircle,
  IconCircleDotted,
  IconInfoCircle,
  IconTools,
  IconGavel,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Tooltip } from "@ensol/entool/components/Tooltip"

export const ProjectIndicators = ({
  project: {
    technicalVisitRequiredChanges,
    isActionNeeded,
    technicalVisitRequiredWorks,
  },
}: {
  project: ProjectResponses.ProjectOverview
}) => {
  const theme = useMantineTheme()
  return (
    <>
      {isActionNeeded ? (
        <IconAlertCircle color={theme.colors.red[5]} size={20} />
      ) : (
        <IconCircleDotted color={theme.colors.gray[5]} size={20} />
      )}

      {technicalVisitRequiredChanges.length > 0 && (
        <Tooltip
          label={
            <Group gap="4">
              <IconInfoCircle size={18} />
              <Text>Changement requis</Text>
            </Group>
          }
        >
          <IconTools color="var(--mantine-color-yellow-8)" size={20} />
        </Tooltip>
      )}
      {technicalVisitRequiredWorks.length > 0 && (
        <Tooltip
          label={
            <Group gap="4">
              <IconInfoCircle size={18} />
              <Text>Travaux requis</Text>
            </Group>
          }
        >
          <IconGavel color="var(--mantine-color-red-8)" size={20} />
        </Tooltip>
      )}
    </>
  )
}
