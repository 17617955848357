import { NumberInput, Stack, TitleOrder, Divider, Button } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconLayout2, IconSolarPanel } from "@tabler/icons-react"
import _ from "lodash"
import { useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { photovoltaicInstallationSchema } from "@ensol/types/forms/installations/photovoltaic"
import { ProductionDataSource } from "@ensol/types/simulation"

import { DEFAULT_PHOTOVOLTAIC_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import {
  computePanelsCount,
  computePanelsCapacity,
} from "@ensol/shared/entities/installations/energy"
import { PHOTOVOLTAIC_SUBSIDIES } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { formatCurrency } from "@ensol/shared/utils/format"

import { FoldableBox } from "@ensol/entool/components/FoldableBox"
import { ExtraWorksSelect } from "@ensol/entool/components/form/Installation/ExtraWorksSelect"
import { ProductWrapper } from "@ensol/entool/components/form/Installation/ProductWrapper"
import { RoofSectionsPanelsForm } from "@ensol/entool/components/form/Installation/RoofSectionsPanelsForm"
import { LegacyField } from "@ensol/entool/components/form/LegacyField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  isNewInstallation: boolean
  titleOrder?: TitleOrder
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  onChange: (
    data: InstallationsResponses.PhotovoltaicInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    | "currentType"
    | "hasFlatRoof"
    | "hasGroundInstallation"
    | "roofSections"
    | "postcode"
    | "constructionYear"
    | "roofType"
  >
  productionDataSource?: ProductionDataSource
  estimatedProduction?: number
  yearlyConsumption?: number
  maxPanelsCount?: number
  totalDiscount: number
}

export const PhotovoltaicInstallation = ({
  isNewInstallation,
  titleOrder,
  photovoltaicInstallation,
  onChange,
  ...props
}: Props) => (
  <ProductWrapper
    type="Photovoltaic"
    installationHasProduct={photovoltaicInstallation !== null}
    addProduct={() =>
      onChange({
        ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
        panelsCount: 0,
        roofSectionsWithPanels: [],
      })
    }
    deleteProduct={() => onChange(null)}
    title="Photovoltaïque"
    titleOrder={titleOrder}
    Icon={IconSolarPanel}
    Actions={
      !isNewInstallation && (
        <Button
          component={Link}
          to="panels-layout"
          w="200"
          leftSection={<IconLayout2 />}
          color="pink"
          variant="light"
        >
          Calepinage
        </Button>
      )
    }
    addButtonLabel="Ajouter des panneaux photovoltaïques"
  >
    {photovoltaicInstallation !== null && (
      <PhotovoltaicInstallationForm
        photovoltaicInstallation={photovoltaicInstallation}
        onChange={onChange}
        {...props}
      />
    )}
  </ProductWrapper>
)

const PhotovoltaicInstallationForm = ({
  photovoltaicInstallation,
  onChange,
  setError,
  clearError,
  house,
  productionDataSource,
  estimatedProduction,
  yearlyConsumption,
  maxPanelsCount,
  totalDiscount,
}: Omit<Props, "isNewInstallation" | "titleOrder"> & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
}) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(
      photovoltaicInstallationSchema.merge(extraWorksSchema),
    ),
    initialValues: {
      ...photovoltaicInstallation,
      roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
        house.roofSections,
        photovoltaicInstallation.roofSectionsWithPanels,
      ),
    },
  })

  const {
    panelType,
    inverterType,
    optimizerType,
    panelsCount,
    optimizerCount,
    subsidyType,
    roofSectionsWithPanels,
  } = form.values

  const {
    panelsOptions,
    invertersOptions,
    optimizerOptions,
    minOptimizerCount,
  } = usePhotovoltaicMaterialOptions({
    panelsCount,
    panelType,
    inverterType,
    currentType: house.currentType,
    installedCapacity: computePanelsCapacity({
      panelsCount,
      panelType,
    }),
  })

  const [recommendedInvertersOptions, otherInvertersOptions] = _.partition(
    invertersOptions,
    (inverter) => !inverter.incompatible,
  )

  const subsidyOptions = useMemo(() => {
    return _.map(PHOTOVOLTAIC_SUBSIDIES, (subsidyInfo, subsidyType) => {
      if (!isFormValid(form)) {
        return { label: subsidyInfo.shortName, value: subsidyType }
      }

      const isEligible = subsidyInfo.isEligible({
        postcode: house.postcode,
        hasGroundInstallation: house.hasGroundInstallation,
      })

      const amount = subsidyInfo.computeAmount({
        photovoltaicInstallation: form.values,
        house,
        totalDiscount,
      })

      return {
        label: subsidyInfo.shortName,
        value: subsidyType,
        disabled: subsidyInfo.disabled || !isEligible,
        subtitle: subsidyInfo.disabled
          ? "Indisponible"
          : isEligible
            ? `Montant: ${formatCurrency(amount)}`
            : "Non éligible",
      }
    })
  }, [form, house, totalDiscount])

  useEffect(() => {
    if (house.hasGroundInstallation) {
      form.setFieldValue("subsidyType", null)
    }
  }, [form, subsidyType, house.hasGroundInstallation])

  useEffect(() => {
    if (findSelectedOption(panelType, panelsOptions)?.incompatible) {
      form.setFieldError("panelType", "Type de panneau invalide")
    } else {
      form.clearFieldError("panelType")
    }

    if (findSelectedOption(optimizerType, optimizerOptions)?.incompatible) {
      form.getInputProps("optimizerType").onChange(null)
    }
  }, [form, panelType, optimizerType, panelsOptions, optimizerOptions])

  useEffect(() => {
    form.setFieldValue(
      "panelsCount",
      computePanelsCount(roofSectionsWithPanels),
    )
  }, [form, roofSectionsWithPanels])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("photovoltaic installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <LegacyField name="Type Panneaux">
        <RadioGroup
          options={panelsOptions}
          {...form.getInputProps("panelType")}
        />
      </LegacyField>
      <Stack>
        <RoofSectionsPanelsForm
          roofSectionsWithPanels={form.values.roofSectionsWithPanels}
          house={house}
          panelType={panelType}
          productionDataSource={productionDataSource}
          yearlyConsumption={yearlyConsumption}
          estimatedProduction={estimatedProduction}
          maxPanelsCount={maxPanelsCount}
          totalPanelsCount={panelsCount}
          {...getSubformActions(form, "roofSectionsWithPanels")}
        />
        <Divider mt={12} mb={24} />
      </Stack>
      <LegacyField name="Onduleurs">
        <RadioGroup
          options={recommendedInvertersOptions}
          {...form.getInputProps("inverterType")}
        />
        <FoldableBox
          name="Autres onduleurs"
          isOpened={otherInvertersOptions
            .map(({ value }) => value)
            .includes(inverterType)}
          align="flex-end"
        >
          <RadioGroup
            options={otherInvertersOptions}
            {...form.getInputProps("inverterType")}
          />
        </FoldableBox>
      </LegacyField>
      <Stack gap="28">
        <LegacyField name="Optimiseurs" noBorder={optimizerType !== null}>
          <RadioGroup
            nullable
            options={optimizerOptions}
            {...form.getInputProps("optimizerType")}
            onChange={(value) => {
              if (value !== null && optimizerCount === null) {
                form.getInputProps("optimizerCount").onChange(panelsCount)
              }
              form.getInputProps("optimizerType").onChange(value)
            }}
          />
        </LegacyField>
        {optimizerType !== null && (
          <LegacyField name="Nombre d'Optimiseurs">
            <NumberInput
              min={minOptimizerCount}
              {...form.getInputProps("optimizerCount")}
              value={optimizerCount ?? panelsCount}
            />
          </LegacyField>
        )}
      </Stack>
      <LegacyField name="Travaux supplémentaires">
        <ExtraWorksSelect {...form.getInputProps("extraWorks")} />
      </LegacyField>
      <LegacyField name="Subventions" noBorder>
        <RadioGroup
          options={subsidyOptions}
          nullable
          {...form.getInputProps("subsidyType")}
        />
      </LegacyField>
    </>
  )
}
