import {
  Button,
  Divider,
  Group,
  Stack,
  Switch,
  Tabs,
  Text,
  Title,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconPlus } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo } from "react"

import {
  HouseInput,
  Location,
  houseFormSchema,
} from "@ensol/types/forms/houses"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import {
  getOrientation,
  ORIENTATIONS,
} from "@ensol/shared/entities/houses/orientation"
import {
  formatRoofSectionName,
  sortRoofSections,
} from "@ensol/shared/entities/houses/roofSection"
import { DEFAULT_HOUSE_CONFIG } from "@ensol/shared/entities/installations/defaults"
import {
  checkHasFlatRoof,
  computeRoofSectionsConfig,
} from "@ensol/shared/material/roofType"
import { useLocationData } from "@ensol/shared/thirdParties/google/location"

import { httpClient } from "@ensol/entool/backend/axios"
import { Map } from "@ensol/entool/components/Map"
import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { AddressAutocomplete } from "@ensol/entool/components/form/House/AddressAutocomplete"
import { HouseEquipments } from "@ensol/entool/components/form/House/HouseEquipments"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { getZIndex } from "@ensol/entool/styles/z-index"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  CURRENT_TYPE_OPTIONS,
  FLOORS_OPTIONS,
  ORIENTATION_OPTIONS,
  ROOF_FRAMING_MATERIALS_OPTIONS,
  ROOF_TYPE_OPTIONS,
} from "@ensol/entool/utils/house/options"

type Props = {
  initialValues?: HouseInput
  onSave: (values: HouseInput) => void
  isLoading: boolean
}

const UPDATE_WARNING =
  "Les panneaux placés sur les anciens pans de toitures seront supprimés de toutes les installations existantes. Êtes-vous sûr de vouloir continuer ?"

export const HouseForm = ({ initialValues, onSave, isLoading }: Props) => {
  const form = useForm<HouseInput>({
    validate: zodResolver(houseFormSchema),
    // @ts-expect-error Allow partial initial values
    initialValues: {
      ...DEFAULT_HOUSE_CONFIG,
      roofSections: [],
      ...initialValues,
    },
  })

  const coords = useMemo(
    () =>
      form.values.lat && form.values.lng ? getCoords(form.values) : undefined,
    [form.values],
  )

  const handleSelectOption = useCallback(
    async (placeDetails: Location) => {
      form.setFieldValue("hasFlatRoof", false)
      form.setFieldValue("hasGroundInstallation", false)
      form.setValues(placeDetails)
    },
    [form],
  )

  const { data: locationData } = useLocationData(httpClient, coords)

  useEffect(() => {
    if (
      locationData &&
      !form.values.roofSections.length &&
      !form.values.hasFlatRoof
    ) {
      form.setFieldValue("roofSections", locationData.roofSections)
    }
  }, [form, form.values.hasFlatRoof, initialValues, locationData])

  // TODO: should it be a useEffect?
  const handleFlatRoofChange = (checked: boolean) => {
    const previousRoofSections = initialValues?.roofSections
      ? sortRoofSections(initialValues.roofSections)
      : undefined

    form.setValues(
      computeRoofSectionsConfig({
        hasFlatRoof: checked,
        roofSections:
          previousRoofSections ??
          locationData?.roofSections ??
          form.values.roofSections,
      }),
    )
  }

  const handleSave = () => {
    const isChangingToFlatRoof =
      initialValues && !initialValues.hasFlatRoof && form.values.hasFlatRoof
    const isChangingToGroundInstallation =
      initialValues &&
      !initialValues.hasGroundInstallation &&
      form.values.hasGroundInstallation

    if (isChangingToGroundInstallation) {
      window.confirm(
        `Vous passez d'un toit existant à une pose au sol. ${UPDATE_WARNING}`,
      ) && onSave(form.values)
    } else if (isChangingToFlatRoof) {
      window.confirm(
        `Vous définissez un toit existant comme plat. ${UPDATE_WARNING}`,
      ) && onSave(form.values)
    } else {
      onSave(form.values)
    }
  }

  return (
    <Stack>
      <Stack mb={44}>
        <Section.Title>Localisation</Section.Title>
        <Group align="flex-start" justify="space-between">
          <Field name="Adresse" noBorder>
            <AddressAutocomplete
              w="100%"
              defaultValue={initialValues?.address}
              handleSelectOption={handleSelectOption}
            />
          </Field>
          <Field
            name="Coordonnées"
            subtitle="Cliquez sur le toit"
            helpText={
              locationData?.imageryDate
                ? `Prise de vue: ${dayjs(locationData.imageryDate).format(
                    "MMMM YYYY",
                  )}`
                : ""
            }
          >
            <Stack w="100%">
              {coords && (
                <Map
                  coords={coords}
                  mapId="modal-map"
                  onPlacePinPoint={(coords) => form.setValues(coords)}
                  style={{ height: 400 }}
                />
              )}
              <Group justify="flex-end" w="100%">
                <NumberInput
                  w={200}
                  label="Latitude"
                  suffix="°"
                  {...form.getInputProps("lat")}
                  disabled={!!initialValues}
                />
                <NumberInput
                  w={200}
                  label="Longitude"
                  suffix="°"
                  {...form.getInputProps("lng")}
                  disabled={!!initialValues}
                />
              </Group>
            </Stack>
          </Field>
        </Group>
        <Section.Title>Énergie</Section.Title>
        <Field name="Type de courant" noBorder>
          <RadioGroup
            options={CURRENT_TYPE_OPTIONS}
            data-test="currentType"
            {...form.getInputProps("currentType")}
          />
        </Field>
        <Field name="Compteur Linky ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("hasLinky")}
          />
        </Field>
        <Section.Title>Toit</Section.Title>
        <Field name="Accès aux combles possible ?" noBorder>
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isAtticAccessible")}
          />
        </Field>
        <Field name="Matériau de la charpente" noBorder>
          <RadioGroup
            options={ROOF_FRAMING_MATERIALS_OPTIONS}
            {...form.getInputProps("roofFramingMaterial")}
          />
        </Field>
        <Field name="Couverture">
          <RadioGroup
            options={ROOF_TYPE_OPTIONS}
            {...form.getInputProps("roofType")}
          />
        </Field>
        <Group justify="space-between" align="flex-start">
          <Group>
            <Title order={4}>Pans de toiture</Title>
            <Button
              size="xs"
              variant="transparent"
              disabled={form.values.hasFlatRoof}
              leftSection={<IconPlus size={18} />}
              onClick={() =>
                form.insertListItem("roofSections", {
                  inclination: 20,
                  orientation: 180,
                })
              }
            >
              Ajouter
            </Button>
          </Group>
          <Group>
            <Switch
              label="Pose au sol ?"
              {...form.getInputProps("hasGroundInstallation", {
                type: "checkbox",
              })}
              onChange={(e) => {
                form.setFieldValue(
                  "hasGroundInstallation",
                  e.currentTarget.checked,
                )
                form.setFieldValue("hasFlatRoof", e.currentTarget.checked)
                handleFlatRoofChange(e.currentTarget.checked)
              }}
            />
            <Switch
              label="Toit plat ?"
              description={
                !form.values.hasFlatRoof &&
                checkHasFlatRoof(form.values.roofSections) ? (
                  <Text c="orange" size="xs" component="span">
                    Le toit est plat
                  </Text>
                ) : undefined
              }
              {...form.getInputProps("hasFlatRoof", {
                type: "checkbox",
              })}
              disabled={form.values.hasGroundInstallation}
              onChange={(e) => {
                form.setFieldValue("hasFlatRoof", e.currentTarget.checked)
                handleFlatRoofChange(e.currentTarget.checked)
              }}
            />
          </Group>
        </Group>
        <Tabs
          orientation="vertical"
          defaultValue="roofSection_0"
          styles={{ list: { width: 160 }, tabLabel: { width: "100%" } }}
        >
          <Tabs.List>
            {form.values.roofSections.map((roofSection, index) => (
              <Tabs.Tab
                key={`roofSection_${index}`}
                value={`roofSection_${index}`}
              >
                <Group wrap="nowrap" justify="space-between">
                  <Text size="sm">
                    {formatRoofSectionName(
                      roofSection,
                      form.values.hasGroundInstallation,
                      form.values.hasFlatRoof,
                    )}
                  </Text>
                </Group>
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {form.values.roofSections.map((_roofSection, index) => {
            const isLocked =
              form.values.hasFlatRoof || form.values.hasGroundInstallation
            return (
              <Tabs.Panel
                ml={16}
                key={`roofSection_${index}`}
                value={`roofSection_${index}`}
              >
                <Stack>
                  <Field name="Orientation" w={FIELD_WIDTH}>
                    <RadioGroup
                      disabled={isLocked}
                      options={ORIENTATION_OPTIONS}
                      {...form.getInputProps(
                        `roofSections.${index}.orientation`,
                      )}
                      value={getOrientation(
                        form.values.roofSections[index].orientation,
                      )}
                      onChange={(value) =>
                        value &&
                        form.setFieldValue(
                          `roofSections.${index}.orientation`,
                          ORIENTATIONS[value].numericValue,
                        )
                      }
                    />
                    <NumberInput
                      w={200}
                      min={0}
                      ml="auto"
                      suffix="°"
                      disabled={isLocked}
                      {...form.getInputProps(
                        `roofSections.${index}.orientation`,
                      )}
                    />
                  </Field>
                  <Field name="Inclinaison">
                    <NumberInput
                      w={200}
                      min={0}
                      ml="auto"
                      suffix="°"
                      disabled={isLocked}
                      {...form.getInputProps(
                        `roofSections.${index}.inclination`,
                      )}
                    />
                  </Field>
                </Stack>
              </Tabs.Panel>
            )
          })}
        </Tabs>
        <Divider mt={12} mb={24} />
        <Section.Title>Caractéristiques</Section.Title>
        <Field name="Année de construction" noBorder>
          <NumberInput w={200} {...form.getInputProps("constructionYear")} />
        </Field>
        <Field name="Maison individuelle ?" noBorder>
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isIndividualHouse")}
          />
        </Field>
        <Field name="Présence d'amiante ?" noBorder>
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("hasAsbestos")}
          />
        </Field>
        <Field name="Bâtiment classé ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isHistoricBuilding")}
          />
        </Field>
        <Field name="Surface" noBorder>
          <NumberInput w={200} suffix="m²" {...form.getInputProps("surface")} />
        </Field>
        <Field name="Nombre d'étages">
          <RadioGroup
            options={FLOORS_OPTIONS}
            {...form.getInputProps("floors")}
          />
        </Field>
        <Section.Title>Équipements</Section.Title>
        <HouseEquipments {...form.getInputProps("equipments")} />
      </Stack>
      <Group
        pos="fixed"
        bottom={0}
        left={0}
        p={16}
        w="100%"
        bg="white"
        justify="flex-end"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Button
          disabled={!form.isValid()}
          loading={isLoading}
          data-test="submitButton"
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </Group>
    </Stack>
  )
}
