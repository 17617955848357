import dayjs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatNumber = (
  value: number,
  options?: { minimumFractionDigits: number },
) => new Intl.NumberFormat("fr", options).format(value)

export const formatCurrency = (
  value: number,
  maximumFractionDigits: number = 2,
): string =>
  new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits:
      Math.floor(value) === value ? 0 : maximumFractionDigits,
    maximumFractionDigits,
  }).format(value)

const splitDuration = (months: number): { years: number; months: number } => {
  if (months > 12)
    return { years: Math.floor(months / 12), months: months % 12 }
  return { years: 0, months }
}

export const formatMonthsCount = (months: number): string => {
  const split = splitDuration(months)
  if (!split.years) return `${split.months} mois`
  if (!split.months) return `${split.years} an${split.years > 1 ? "s" : ""}`
  return `${split.years} an${split.years > 1 ? "s" : ""} et ${
    split.months
  } mois`
}

export const isValidFrenchPhoneNum = (phoneNum: string) =>
  isValidPhoneNumber(phoneNum, {
    defaultCountry: "FR",
  })

export const formatPhoneNum = (
  phoneNum: string,
  mode: "national" | "international" | "E.164" | "uri" = "national",
): string => {
  if (isValidFrenchPhoneNum(phoneNum)) {
    const parsedPhoneNum = parsePhoneNumber(phoneNum, {
      defaultCountry: "FR",
    })

    switch (mode) {
      case "national":
        return parsedPhoneNum.country === "FR"
          ? parsedPhoneNum.formatNational()
          : parsedPhoneNum.formatInternational()
      case "international":
        return parsedPhoneNum.formatInternational()
      case "E.164":
        return parsedPhoneNum.format("E.164")
      case "uri":
        return parsedPhoneNum.format("RFC3966")
    }
  }

  return phoneNum
}

export const roundDecimalNumber = (
  value: number,
  decimals: number = 2,
): number => {
  return Math.round(value * 10 ** decimals) / 10 ** decimals
}

export const DISPLAY_DATE_FORMAT = "dddd DD MMMM YYYY"
export const SHORT_DISPLAY_DATE_FORMAT = "DD/MM/YY"
export const DISPLAY_DATE_TIME_FORMAT = "dddd DD MMMM YYYY [à] HH[h]mm"
export const SHORT_DISPLAY_DATE_TIME_FORMAT = "DD/MM/YY [à] HH[h]mm"
export const WEEK_FORMAT = "[Semaine du] DD/MM"

export const formatDate = (
  date: Date | Dayjs | string | null | undefined,
  format = DISPLAY_DATE_FORMAT,
) => {
  if (date === null || date === undefined) {
    return null
  }

  if (typeof date === "string" || date instanceof Date) {
    return dayjs(date).tz("Europe/Paris").format(format)
  }
  return date.format(format)
}
