import { Paper, Stack, Loader, Text } from "@mantine/core"
import { useEffect, useState } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { SalesUpdateInstallationInput } from "@ensol/types/forms/installations"
import { HouseSignedInstallations } from "@ensol/types/installation"
import { SimulationResults } from "@ensol/types/simulation"

import { computeSignedInstallationsPanelsCount } from "@ensol/shared/entities/installations/energy"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { runSimulation } from "@ensol/shared/entities/installations/simulation"

import { CostsSummary } from "./CostsSummary"
import { SimulationSummary } from "./SimulationSummary"

type Props = {
  installationDate: Date
  installationValues: SalesUpdateInstallationInput
  energyStats?: EnergyResponses.Stats | null
  isEnergyStatsLoading: boolean
  house: HousesResponses.House<{ include: { roofSections: true } }>
  signedInstallations?: HouseSignedInstallations
  isInstallationValid: boolean
  isNewInstallation: boolean
}

export const Summary = ({
  installationDate,
  installationValues,
  energyStats,
  isEnergyStatsLoading,
  house,
  signedInstallations,
  isNewInstallation,
  isInstallationValid,
}: Props) => {
  const [simulationResult, setInstallationResult] =
    useState<SimulationResults>()

  useEffect(() => {
    if (isInstallationValid && energyStats) {
      const result = runSimulation({
        installationDate,
        monthlyBill: installationValues.monthlyBill,
        yearlyConsumption: energyStats.yearlyConsumption,
        autoConsumptionPercent: installationValues.autoConsumptionPercent,
        estimatedYearlyProduction: energyStats.yearlyProduction,
        photovoltaicInstallation: installationValues.photovoltaicInstallation,
        extraPanelsInstallation: installationValues.extraPanelsInstallation,
        evChargerInstallation: installationValues.evChargerInstallation,
        batteryInstallation: installationValues.batteryInstallation,
        house,
        totalDiscount: computeTotalDiscount(installationValues.discount),
        signedInstallations,
      })
      setInstallationResult(result)
    }
  }, [
    installationValues,
    house,
    isInstallationValid,
    installationDate,
    energyStats,
    signedInstallations,
  ])

  const panelsCount =
    installationValues.photovoltaicInstallation?.panelsCount ??
    installationValues.extraPanelsInstallation?.panelsCount ??
    0

  const installedPanelsCount =
    computeSignedInstallationsPanelsCount(signedInstallations)

  if (isEnergyStatsLoading) {
    return (
      <Paper withBorder shadow="none" w={400} h={200} px={24} py={16}>
        <Stack flex="1" h="100%" align="center" justify="center">
          <Loader size="sm" color="gray" />
        </Stack>
      </Paper>
    )
  }

  if (isNewInstallation && !isInstallationValid) {
    return (
      <Paper withBorder shadow="none" w={400} px={24} py={16}>
        <Text c="gray.7">
          Renseignez les informations pour voir le résultat de
          l&apos;installation
        </Text>
      </Paper>
    )
  }

  if (!isInstallationValid) {
    return null
  }

  return (
    <Paper withBorder shadow="none" w={400} px={24} py={16}>
      {simulationResult !== undefined && panelsCount !== undefined && (
        <SimulationSummary
          simulationResult={simulationResult}
          installedPanelsCount={installedPanelsCount}
          panelsCount={panelsCount}
        />
      )}
      <CostsSummary
        installationValues={installationValues}
        house={house}
        simulationResult={simulationResult}
      />
    </Paper>
  )
}
