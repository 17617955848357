import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  ProductCosts,
  withMargin,
} from "@ensol/shared/entities/installations/costs"
import { computeExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import { Subsidy } from "@ensol/shared/entities/installations/subsidies"
import {
  EV_CHARGER_SUBSIDIES,
  EvChargerSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/evCharger"
import {
  getEvCharger,
  getEvChargerSmartMeter,
} from "@ensol/shared/material/evCharger"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const EV_CHARGER_VAT_RATE = 0.055

// MATERIAL COSTS {€)
// Support + Cables
const EV_CHARGER_ACCESSORIES_PRICE = 39.84 // €

// WORKFORCE COSTS {€)
export const EV_CHARGER_WORKFORCE = 950 // €

export const computeEvChargerUnitPrice = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): number => {
  const evCharger = getEvCharger(evChargerInstallation.type)
  return withMargin(evCharger.price)
}

export const computeEvChargerSmartMeterPrice = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): number => {
  const smartMeter = getEvChargerSmartMeter(evChargerInstallation.type)
  return withMargin(smartMeter.price)
}

export const computeEvChargerAccessoriesPrice = () =>
  withMargin(EV_CHARGER_ACCESSORIES_PRICE)

const computeEvChargerMaterialCost = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): number =>
  computeEvChargerAccessoriesPrice() +
  computeEvChargerUnitPrice(evChargerInstallation) +
  computeEvChargerSmartMeterPrice(evChargerInstallation)

export const computeEvChargerWorkforceCost = () =>
  withMargin(EV_CHARGER_WORKFORCE)

export const computeEvChargerInstallationCost = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): number => {
  const materialCostHT = computeEvChargerMaterialCost(evChargerInstallation)
  const extraWorksCostHT = computeExtraWorksCosts(evChargerInstallation)
  const workforceCostHT =
    computeEvChargerWorkforceCost() + evChargerInstallation.additionalCost

  const totalCostHT = materialCostHT + extraWorksCostHT + workforceCostHT

  return roundDecimalNumber(totalCostHT * (1 + EV_CHARGER_VAT_RATE))
}

type AllCostsInput = {
  evChargerInstallation: InstallationsResponses.EvChargerInstallation
}

export const computeAllEvChargerCosts = ({
  evChargerInstallation,
}: AllCostsInput): ProductCosts & {
  subsidy: Subsidy<EvChargerSubsidyType> | null
} => {
  const installationCost = computeEvChargerInstallationCost(
    evChargerInstallation,
  )

  const installationCostBeforeTax = roundDecimalNumber(
    installationCost / (1 + EV_CHARGER_VAT_RATE),
  )

  const subsidyType = evChargerInstallation.subsidyType
  const subsidy =
    subsidyType !== null
      ? {
          subsidyType,
          subsidyAmount: EV_CHARGER_SUBSIDIES[subsidyType].computeAmount({
            evChargerInstallation,
          }),
        }
      : null

  const installationCostWithSubsidies = roundDecimalNumber(
    installationCost - (subsidy?.subsidyAmount ?? 0),
  )

  return {
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
    subsidy,
    vatRate: EV_CHARGER_VAT_RATE,
  }
}
