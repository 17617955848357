import { Text, Group, Anchor } from "@mantine/core"
import {
  IconAt,
  IconHeartHandshake,
  IconMap2,
  IconPhone,
  IconSolarPanel,
} from "@tabler/icons-react"
import { Link as ReactRouterLink } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import intercomLogo from "@ensol/entool/assets/intercomLogo.png"
import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { Link } from "@ensol/entool/components/Link"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { InstallationProducts } from "@ensol/entool/components/entities/Installation/InstallationProducts"
import { ProjectHeader } from "@ensol/entool/components/entities/Project/Header"
import { INTERCOM_USERS_URL } from "@ensol/entool/config"
import { OVERVIEW_TAB_ID } from "@ensol/entool/pages/OpsProject/Project"
import { OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

import { ProjectOwners } from "./ProjectOwners"

type Props = {
  project: ProjectResponses.Project
  currentTab: string
}

export const Header = ({ project, currentTab }: Props) => {
  const { installation } = project
  const { house, referrer } = installation
  const { prospect } = house

  const installers = getProjectInstallers(project)

  return (
    <ProjectHeader
      projectId={project.id}
      prospect={prospect}
      backPath={
        currentTab === OVERVIEW_TAB_ID
          ? "/projects"
          : `/projects/processes/${currentTab}`
      }
      height={OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX}
      LeftComponent={
        <>
          <Group>
            <InstallationProducts installation={project.installation} />
            <HeaderInfos
              infos={[
                { Icon: IconMap2, text: house.address },
                { Icon: IconAt, text: prospect.email },
                { Icon: IconPhone, text: prospect.phone },
              ]}
            />
          </Group>
          {referrer && (
            <Group gap="4">
              <IconHeartHandshake size={16} />
              <Text size="sm">Projet parrainé par</Text>
              <Anchor
                component={ReactRouterLink}
                size="sm"
                fw="bold"
                to={`/prospects/${referrer.id}`}
              >
                {getFullName(referrer)}
              </Anchor>
            </Group>
          )}
          <Group gap="8" mt="16">
            <ProjectOwners project={project} background="blue.0" />
          </Group>
        </>
      }
      RightComponent={
        <>
          <InstallationCharacteristics
            installation={installation}
            installers={installers}
          />
          <Group mt="8">
            {prospect.intercomUserId !== null && (
              <Link
                label="Profil Intercom"
                href={`${INTERCOM_USERS_URL}/${prospect.intercomUserId}`}
                Icon={<img src={intercomLogo} height={20} />}
                color="black"
                isExternal
              />
            )}
            <Link
              label="Installation"
              href={`/installations/${installation.id}`}
              Icon={<IconSolarPanel />}
            />
          </Group>
        </>
      }
    />
  )
}
